import React, { useEffect, useState } from "react";
import "./myaddress.css";
import "./Address/address.css";
import DefaultLayout from "../../layout/defaultLayout";
// import { useTranslation } from 'react-i18next'
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { BsChevronDown } from "react-icons/bs";
import { Toggle } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get_towns } from "../UserSystem/functions";
import { SelectPicker } from "rsuite";
import EditMap from './EditMAp/EditMap';
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../CONSTS";

const EditAddressForm = ({ oldData, handlechange, towns_2 }) => {
  // console.log(oldData,'dds')
  const accessToken = localStorage.getItem("accessToken");
  const [towns, setTowns] = useState(false);
  const language = useSelector((state) => state.language.lang);
  const options = {
    headers: {
      "Accept-Language": language,
    },
  };
  const data = JSON.parse(accessToken);
  const [allolddata, setallolddata] = useState({
    address: oldData.address,
    name: oldData.name,
    city: oldData.city,
    state: oldData.state,
    is_default: oldData.is_default,
    late: oldData.late,
    lang: oldData.lang,
    map_location: oldData.map_location,
  });

  const eqdata = () => {
    let myobj = {
      address: oldData.address,
      name: oldData.name,
      // city:oldData.city,
      state: oldData.state,
      is_default: oldData.is_default,
    };
    // console.log(myobj);
    setallolddata({
      address: oldData.address,
      name: oldData.name,
      city: '',
      state: oldData.state,
      is_default: oldData.is_default,
    });
  };

  useEffect(() => {
    setallolddata({
      address: oldData.address,
      name: oldData.name,
      city: oldData.city,
      state: oldData.state,
      is_default: oldData.is_default,
      late: oldData.late,
      lang: oldData.lang,
      map_location: oldData.map_location,
    });
  }, []);
  const [getTowns, setTownsName] = useState(false);
  useEffect(() => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    get_towns({ options }).then((res) => {
      setTowns(res);
      let mycity = [];
      mycity = res.filter((item) => item.id == oldData?.city.id);
      setTownsName([
        ...res.map((item) => ({
          id: item?.id,
          name: item?.name,
        })),
      ]);
      setallolddata({ ...allolddata, city: mycity[0]?.id });
    });
  }, [language]);
  const handleupdate = () => {
    const formdata = new FormData();
    formdata.append("id", oldData.id);
    formdata.append("name", allolddata.name);
    formdata.append("address", allolddata.address);
    formdata.append("city", allolddata.city);
    formdata.append("state", allolddata.state);
    formdata.append("late", locationName?.latlng?.lat);
    formdata.append("lang", locationName?.latlng?.lng);
    formdata.append("is_default", allolddata.is_default == true ? 1 : 0);
    formdata.append("map_location", locationName.map_location);
    formdata.forEach((value, key) => {
      // console.log(`${key}: ${value}`);
    });

    axios
      .post(
        `${BASE_URL}api/web/v1/product/update-address?access-token=${data?.access_token}`,
        formdata,
        options
      )
      .then((res) => {
        // console.log(res.data.data);
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          toast.success(res?.data?.data[0]?.message);
          handlechange();
          window.location.reload();
          window.location.reload();
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(language == 'ar' ? "حدث خطأ ما" : "something went wrong");
      });
  };
  // console.log("allolddata", allolddata);
  const [locationName, setLocationName] = useState({
    map_location: allolddata?.map_location,
    latlng: {
      lat: parseFloat(allolddata?.late),
      lng: parseFloat(allolddata?.lang),
    },
  });

  return (
    <div className="add_location_form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleupdate();
        }}
      >
        <div>
          <label htmlFor="loc">
            {language === "ar" ? "العنوان" : "Address"}
          </label>
          <input
            onChange={(e) => {
              setallolddata({ ...allolddata, address: e.target.value });
            }}
            value={allolddata.address}
            id="loc"
            type="text"
            placeholder={language === "ar" ? "العنوان" : "Address"}
            name="address"
          />
        </div>
        <div>
          <label htmlFor="loc">
            {language === "ar" ? "عنوان العمل" : "Work Address"}{" "}
          </label>
          <input
            onChange={(e) => {
              setallolddata({ ...allolddata, name: e.target.value });
            }}
            value={allolddata.name}
            id="loc"
            type="text"
            placeholder={language === "ar" ? "عنوان العمل" : "Work Address"}
            name="name"
          />
        </div>
        {towns_2 && towns_2.length ? (
          <div style={{ minHeight: '50px' }}>
            <label htmlFor="city">
              {language === "ar" ? "المدينه" : "City"}
            </label>
            <div className="city_div">
              <SelectPicker
                // value={allolddata?.city}
                onChange={(e) => {
                  setallolddata({
                    ...allolddata,
                    city: e?.value ? e?.value : e,
                  });
                }}
                style={{ width: "100%", margin: "15px 0" }}
                data={towns_2.map((item) => {
                  return {
                    label: language == "ar" ? item?.name : item?.name_en,
                    value: item?.id,
                  };
                })}
                type="text"
                placeholder={language === "ar" ? "المدينه" : "City"}
                name="city"
              />
            </div>
          </div>
        ) : null}
        <div style={{ marginBottom: "40px" }}>
          <label htmlFor="district">
            {language === "ar" ? "الحي" : "District"}
          </label>
          <input
            onChange={(e) => {
              setallolddata({ ...allolddata, state: e.target.value });
            }}
            value={allolddata.state}
            type="text"
            placeholder={language === "ar" ? "الحي" : "District"}
            name="state"
          />
        </div>

        <div className="arelocdef" style={{ display: "flex" }}>
          <label htmlFor="is_default">
            {language === "ar"
              ? "هل هذا العنوان افتراضي"
              : "Is This Address default"}
          </label>
          <Toggle
            onChange={(e) => {
              setallolddata({
                ...allolddata,
                is_default: !allolddata.is_default,
              });
            }}
            checked={allolddata.is_default == 1 ? true : false}
            name="is_default"
          />
        </div>

        <EditMap
          setLocationName={setLocationName}
          locdata={{
            lat: parseFloat(allolddata?.late),
            lng: parseFloat(allolddata?.lang),
          }}
        />
        {locationName?.map_location &&
        locationName?.latlng?.lat &&
        locationName?.latlng?.lng ? (
          <div>
            <label htmlFor="district">
              {language === "ar" ? "عنوان الخريطة" : "Map Location"}
            </label>
            <p>
              {locationName?.map_location +
                "-" +
                locationName?.latlng?.lat +
                "-" +
                locationName?.latlng?.lng}
            </p>
          </div>
        ) : null}
        <button
          className="add_btn"
          style={{
            background: "var(--main-color)",
            color: "white",
            // border: "4px dotted var(--main-color)",
            padding: "15px",
            margin: "5px 0",
            width: "100%",
            fontSize: "18px",
            borderRadius: "6px",
          }}
          // onClick={()=>{
          // }}
        >
          {language == "ar" ? " تعديل العنوان" : "Edit Location"}{" "}
        </button>
      </form>
    </div>
  );
};

export default EditAddressForm;
