import React, { useEffect, useState } from 'react';
import Brudcrumb from '../../components/brudcrumb/brudcrumb';
import DefaultLayout from '../../layout/defaultLayout';
import './paymentmethod.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletecart,
  handleremovelocaldata,
  updateCartDataPayement,
  emptycart,
  updatepaymentstatus,
} from '../../store/cartReducer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { Loader } from 'rsuite';
import { BASE_URL } from '../../CONSTS';

const PaymentMethod = () => {
  const accessToken = localStorage.getItem("accessToken");
  const language = useSelector((state) => state.language.lang);
  const data = accessToken ? JSON.parse(accessToken) : null;
  const dispatch = useDispatch();
  const getCartData = useSelector((state) => state.cart);

  const [loading, setloading] = useState(false);

  const product = useSelector((s) => s.cart);
  // console.log(product)
  const getBranch = useSelector((state) => state.branches.branch);

  useEffect(() => {
    const currentTime = new Date();

    const desiredOpenTime = new Date(currentTime);
    desiredOpenTime.setHours(...getBranch?.open_time?.split(":"));

    const desiredCloseTime = new Date(currentTime);
    desiredCloseTime.setHours(...getBranch?.closed_time?.split(":"));

    const openTime = currentTime >= desiredOpenTime;
    const closedTime = currentTime <= desiredCloseTime;
    // console.log(getBranch,!closedTime)
    if (!closedTime && !openTime) {
      toast.error(
        language == "ar"
          ? "الوقت الذي أدخلته خارج دوام الفرع"
          : "The branch is not open at the moment"
      );
      return navigate("/");
    }
  }, [getBranch]);

  const getCart = useSelector((state) => state.cart.data);
  useEffect(() => {
    if (!getCart.length) {
      return navigate("/");
    }
  }, [getCart]);

  const getUser = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!getUser && !getUser.length) {
      return navigate("/");
    }
  }, [getUser]);

  const [payments, setpayments] = useState([]);
  const navigate = useNavigate();
  const [selected, setselected] = useState(0);
  const [paytype, setpaytype] = useState("");

  const getpaymentmethods = () => {
    const options = {
      headers: {
        'Accept-Language': "ar", // Set the desired language code here
      },
    };
    axios.get(`${BASE_URL}api/web/v1/list/card-type`, options).then((res) => {
      setpayments(res.data.data[0].CardType);
      // console.log(res.data.data[0].CardType)
    });
  };

  const [showmodConFitnm, setshowmodConFitnm] = useState(false);
  const [status, setStatus] = useState(1);
  const handleaddorder = () => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("username", data?.data?.username);
    formdata.append("country", data?.data?.city);
    formdata.append("region", data?.data?.region);
    formdata.append("address", product?.address);
    formdata.append("mobile", data?.data?.mobile);
    formdata.append("description", product?.description);
    formdata.append("status", status);
    formdata.append("order_time", product?.order_time);
    formdata.append("branch_id", getBranch?.id);
    formdata.append("delivery_status", product?.waydeliv);
    formdata.append("totalprice", product?.product_totalPrice);
    formdata.append("number_of_items", product?.product_quantity);
    formdata.append("totalquantity", product?.totalquantity);
    formdata.append("product_ids", product?.product_ids);
    formdata.append("product_name", product?.product_name);
    formdata.append("product_price", product?.product_price);
    formdata.append("product_quantity", product?.product_quantity);
    formdata.append("product_extension", product?.product_extension);
    formdata.append("product_size", product?.product_size);
    formdata.append("product_rice", product?.product_rice);
    formdata.append("product_dish", product?.product_dish);
    formdata.append("product_totalPrice", product?.product_totalPrice);
    formdata.append("lat", product?.lat);
    formdata.append("lang", product?.lang);
    formdata.append("copun", '');
    // console.log(formdata);
    axios
      .post(
        `${BASE_URL}api/web/v1/product/checkout?access-token=${data?.access_token}`,
        formdata
      )
      .then((res) => {
        // console.log(res, formdata);
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          // dispatch(emptycart())
          // localStorage.removeItem("talabaty")
          // window.location.reload()
          toast.success(res?.data?.data[0]?.message);
          dispatch(emptycart());
          navigate("/mypurchases");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "something wen error");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
    // console.log(formdata, getCartData);
  };

  useEffect(() => {
    getpaymentmethods();
  }, []);
  const [minmalData, setMinimalData] = useState(false);
  const getminimal = () => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .get(`${BASE_URL}api/web/v1/product/order-limit`, options)
      .then((res) => {
        // console.log("MinimalData", res.data.data);
        setMinimalData(res.data.data);
      });
  };
  useEffect(() => {
    getminimal();
  }, []);
  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb title={"وسيلة الدفع"} />
          <div className="paymentmethod" dir="ltr">
            {!minmalData?.hide_bank_payment ? (
              <div className="payment" style={{ flexDirection: "row-reverse" }}>
                <div className="payment_left">
                  <h5>{language == 'ar' ? "الدفع عن الاستلام كاش" : "cash"}</h5>
                  <img src={require("../../assets/pay1.png")} alt="" />
                </div>
                <div className="payment_right">
                  <div
                    onClick={() => {
                      setselected("cash");
                      setpaytype("cash");
                      setStatus(1);
                      dispatch(updatepaymentstatus({ paymentstatus: 1 }));
                    }}
                    className={
                      selected === "cash" ? 'payselect active' : 'payselect'
                    }
                  ></div>
                </div>
              </div>
            ) : null}

            {!minmalData?.hide_tasleem_payment ? (
              <div className="payment" style={{ flexDirection: "row-reverse" }}>
                <div className="payment_left">
                  <h5>
                    {language == 'ar'
                      ? "الدفع عند الاستلام بطاقة مدي"
                      : "Pay With Mada"}
                  </h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32.847"
                    height="35.22"
                    viewBox="0 0 32.847 35.22"
                  >
                    <g id="credit" transform="translate(-0.5 0)">
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M36.449,20.027,20.509,35.966a1.844,1.844,0,0,1-2.608,0L8.522,26.587a1.844,1.844,0,0,1,0-2.608L24.461,8.04a1.845,1.845,0,0,1,2.608,0l9.38,9.38a1.845,1.845,0,0,1,0,2.608Zm0,0"
                        transform="translate(-6.967 -6.984)"
                        fill="#8cbafa"
                      />
                      <path
                        id="Path_86"
                        data-name="Path 86"
                        d="M154.886,135.854l-1.171-1.171a1.752,1.752,0,0,1-.143,2.466l-15.939,15.939a1.752,1.752,0,0,1-2.465.143l1.17,1.171a1.844,1.844,0,0,0,2.608,0l15.939-15.939A1.845,1.845,0,0,0,154.886,135.854Zm0,0"
                        transform="translate(-125.405 -125.419)"
                        fill="#5692d8"
                      />
                      <path
                        id="Path_87"
                        data-name="Path 87"
                        d="M216.376,61.93l-1.562,1.562a1.572,1.572,0,0,0,2.223,2.223l1.562-1.562a1.572,1.572,0,0,0-2.223-2.223Zm0,0"
                        transform="translate(-199.143 -57.241)"
                        fill="#ffcd34"
                      />
                      <path
                        id="Path_88"
                        data-name="Path 88"
                        d="M113.581,258.051H91.04A1.844,1.844,0,0,0,89.2,259.9V273.16A1.844,1.844,0,0,0,91.04,275h22.541a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,113.581,258.051Zm0,0"
                        transform="translate(-82.594 -240.3)"
                        fill="#ffcd34"
                      />
                      <path
                        id="Path_89"
                        data-name="Path 89"
                        d="M421.339,258.051h-1.651a1.844,1.844,0,0,1,1.844,1.844V273.16A1.844,1.844,0,0,1,419.688,275h1.651a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,421.339,258.051Zm0,0"
                        transform="translate(-390.352 -240.3)"
                        fill="#e69012"
                      />
                      <path
                        id="Path_90"
                        data-name="Path 90"
                        d="M89.2,318.625h26.23v3.187H89.2Zm0,0"
                        transform="translate(-82.594 -296.707)"
                        fill="#575b7a"
                      />
                      <path
                        id="Path_91"
                        data-name="Path 91"
                        d="M446.5,318.625h1.651v3.187H446.5Zm0,0"
                        transform="translate(-415.316 -296.707)"
                        fill="#252d4c"
                      />
                      <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M376.781,435.244v1.965a.275.275,0,0,0,.275.275h3.126a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-3.126A.275.275,0,0,0,376.781,435.244Zm0,0"
                        transform="translate(-350.397 -405.048)"
                        fill="#e9e9ea"
                      />
                      <path
                        id="Path_93"
                        data-name="Path 93"
                        d="M239.762,435.244v1.965a.275.275,0,0,0,.275.275h6.612a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-6.612A.275.275,0,0,0,239.762,435.244Zm0,0"
                        transform="translate(-222.803 -405.048)"
                        fill="#e9e9ea"
                      />
                      <path
                        id="Path_94"
                        data-name="Path 94"
                        d="M1.92,71.176a1.33,1.33,0,0,1,0-1.879L14.562,56.655a.516.516,0,0,0-.73-.73L1.19,68.568a2.363,2.363,0,0,0,0,3.338L4.374,75.09a.516.516,0,1,0,.73-.73Zm0,0"
                        transform="translate(0 -51.938)"
                      />
                      <path
                        id="Path_95"
                        data-name="Path 95"
                        d="M60.847,147.32l-7.807,7.807a.516.516,0,0,0,.73.73l7.807-7.807a.516.516,0,0,0-.73-.73Zm0,0"
                        transform="translate(-48.785 -137.045)"
                      />
                      <path
                        id="Path_96"
                        data-name="Path 96"
                        d="M208.944,59.722a2.082,2.082,0,0,0,1.477-.611l1.562-1.562a2.088,2.088,0,1,0-2.953-2.953l-1.562,1.562a2.087,2.087,0,0,0,1.477,3.564Zm-.747-2.834,1.562-1.562a1.056,1.056,0,0,1,1.494,1.494l-1.562,1.562a1.056,1.056,0,0,1-1.494-1.494Zm0,0"
                        transform="translate(-192.161 -50.273)"
                      />
                      <path
                        id="Path_97"
                        data-name="Path 97"
                        d="M106.6,17.235h-4.966l3.826-3.826a2.363,2.363,0,0,0,0-3.338L96.078.691a2.36,2.36,0,0,0-3.338,0L90.9,2.529a.516.516,0,0,0,.73.73l1.838-1.838a1.329,1.329,0,0,1,1.879,0l9.379,9.379a1.33,1.33,0,0,1,0,1.879l-4.556,4.556H87.133l3.75-3.75a.516.516,0,1,0-.73-.73l-4.48,4.48H84.056A2.363,2.363,0,0,0,81.7,19.6V32.86a2.363,2.363,0,0,0,2.36,2.36H106.6a2.363,2.363,0,0,0,2.36-2.36V30.953a.516.516,0,1,0-1.032,0V32.86a1.33,1.33,0,0,1-1.329,1.328H84.056a1.33,1.33,0,0,1-1.328-1.328V25.621h25.2v3.269a.516.516,0,1,0,1.032,0V19.6a2.363,2.363,0,0,0-2.36-2.36ZM84.056,18.267H106.6a1.33,1.33,0,0,1,1.329,1.328V21.4h-25.2V19.6A1.33,1.33,0,0,1,84.056,18.267Zm-1.328,6.322V22.434h25.2v2.155Zm0,0"
                        transform="translate(-75.611)"
                      />
                      <path
                        id="Path_98"
                        data-name="Path 98"
                        d="M370.072,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791H373.2a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm2.885,2.516h-2.645V428.5h2.645Zm0,0"
                        transform="translate(-343.413 -398.064)"
                      />
                      <path
                        id="Path_99"
                        data-name="Path 99"
                        d="M233.053,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791h6.612a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm6.371,2.516h-6.131V428.5h6.131Zm0,0"
                        transform="translate(-215.819 -398.064)"
                      />
                      <path
                        id="Path_100"
                        data-name="Path 100"
                        d="M145.707,464.039a.516.516,0,0,0,0,1.032H149a.516.516,0,1,0,0-1.032Zm0,0"
                        transform="translate(-134.738 -432.118)"
                      />
                    </g>
                  </svg>
                </div>
                <div className="payment_right">
                  <div
                    onClick={() => {
                      setselected("mada");
                      setpaytype("mada");
                      setStatus(5);
                      dispatch(updatepaymentstatus({ paymentstatus: 5 }));
                    }}
                    className={
                      selected === "mada" ? 'payselect active' : 'payselect'
                    }
                  ></div>
                </div>
              </div>
            ) : null}
            {/* {console.log("minmalData", minmalData)} */}
            {!minmalData?.hide_elec_payment ? (
              <>
                <div
                  className="payment"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <div className="payment_left">
                    <h5>
                      {language == 'ar' ? "الدفع الالكتروني" : "Electronic pay"}
                    </h5>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32.847"
                      height="35.22"
                      viewBox="0 0 32.847 35.22"
                    >
                      <g id="credit" transform="translate(-0.5 0)">
                        <path
                          id="Path_85"
                          data-name="Path 85"
                          d="M36.449,20.027,20.509,35.966a1.844,1.844,0,0,1-2.608,0L8.522,26.587a1.844,1.844,0,0,1,0-2.608L24.461,8.04a1.845,1.845,0,0,1,2.608,0l9.38,9.38a1.845,1.845,0,0,1,0,2.608Zm0,0"
                          transform="translate(-6.967 -6.984)"
                          fill="#8cbafa"
                        />
                        <path
                          id="Path_86"
                          data-name="Path 86"
                          d="M154.886,135.854l-1.171-1.171a1.752,1.752,0,0,1-.143,2.466l-15.939,15.939a1.752,1.752,0,0,1-2.465.143l1.17,1.171a1.844,1.844,0,0,0,2.608,0l15.939-15.939A1.845,1.845,0,0,0,154.886,135.854Zm0,0"
                          transform="translate(-125.405 -125.419)"
                          fill="#5692d8"
                        />
                        <path
                          id="Path_87"
                          data-name="Path 87"
                          d="M216.376,61.93l-1.562,1.562a1.572,1.572,0,0,0,2.223,2.223l1.562-1.562a1.572,1.572,0,0,0-2.223-2.223Zm0,0"
                          transform="translate(-199.143 -57.241)"
                          fill="#ffcd34"
                        />
                        <path
                          id="Path_88"
                          data-name="Path 88"
                          d="M113.581,258.051H91.04A1.844,1.844,0,0,0,89.2,259.9V273.16A1.844,1.844,0,0,0,91.04,275h22.541a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,113.581,258.051Zm0,0"
                          transform="translate(-82.594 -240.3)"
                          fill="#ffcd34"
                        />
                        <path
                          id="Path_89"
                          data-name="Path 89"
                          d="M421.339,258.051h-1.651a1.844,1.844,0,0,1,1.844,1.844V273.16A1.844,1.844,0,0,1,419.688,275h1.651a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,421.339,258.051Zm0,0"
                          transform="translate(-390.352 -240.3)"
                          fill="#e69012"
                        />
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M89.2,318.625h26.23v3.187H89.2Zm0,0"
                          transform="translate(-82.594 -296.707)"
                          fill="#575b7a"
                        />
                        <path
                          id="Path_91"
                          data-name="Path 91"
                          d="M446.5,318.625h1.651v3.187H446.5Zm0,0"
                          transform="translate(-415.316 -296.707)"
                          fill="#252d4c"
                        />
                        <path
                          id="Path_92"
                          data-name="Path 92"
                          d="M376.781,435.244v1.965a.275.275,0,0,0,.275.275h3.126a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-3.126A.275.275,0,0,0,376.781,435.244Zm0,0"
                          transform="translate(-350.397 -405.048)"
                          fill="#e9e9ea"
                        />
                        <path
                          id="Path_93"
                          data-name="Path 93"
                          d="M239.762,435.244v1.965a.275.275,0,0,0,.275.275h6.612a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-6.612A.275.275,0,0,0,239.762,435.244Zm0,0"
                          transform="translate(-222.803 -405.048)"
                          fill="#e9e9ea"
                        />
                        <path
                          id="Path_94"
                          data-name="Path 94"
                          d="M1.92,71.176a1.33,1.33,0,0,1,0-1.879L14.562,56.655a.516.516,0,0,0-.73-.73L1.19,68.568a2.363,2.363,0,0,0,0,3.338L4.374,75.09a.516.516,0,1,0,.73-.73Zm0,0"
                          transform="translate(0 -51.938)"
                        />
                        <path
                          id="Path_95"
                          data-name="Path 95"
                          d="M60.847,147.32l-7.807,7.807a.516.516,0,0,0,.73.73l7.807-7.807a.516.516,0,0,0-.73-.73Zm0,0"
                          transform="translate(-48.785 -137.045)"
                        />
                        <path
                          id="Path_96"
                          data-name="Path 96"
                          d="M208.944,59.722a2.082,2.082,0,0,0,1.477-.611l1.562-1.562a2.088,2.088,0,1,0-2.953-2.953l-1.562,1.562a2.087,2.087,0,0,0,1.477,3.564Zm-.747-2.834,1.562-1.562a1.056,1.056,0,0,1,1.494,1.494l-1.562,1.562a1.056,1.056,0,0,1-1.494-1.494Zm0,0"
                          transform="translate(-192.161 -50.273)"
                        />
                        <path
                          id="Path_97"
                          data-name="Path 97"
                          d="M106.6,17.235h-4.966l3.826-3.826a2.363,2.363,0,0,0,0-3.338L96.078.691a2.36,2.36,0,0,0-3.338,0L90.9,2.529a.516.516,0,0,0,.73.73l1.838-1.838a1.329,1.329,0,0,1,1.879,0l9.379,9.379a1.33,1.33,0,0,1,0,1.879l-4.556,4.556H87.133l3.75-3.75a.516.516,0,1,0-.73-.73l-4.48,4.48H84.056A2.363,2.363,0,0,0,81.7,19.6V32.86a2.363,2.363,0,0,0,2.36,2.36H106.6a2.363,2.363,0,0,0,2.36-2.36V30.953a.516.516,0,1,0-1.032,0V32.86a1.33,1.33,0,0,1-1.329,1.328H84.056a1.33,1.33,0,0,1-1.328-1.328V25.621h25.2v3.269a.516.516,0,1,0,1.032,0V19.6a2.363,2.363,0,0,0-2.36-2.36ZM84.056,18.267H106.6a1.33,1.33,0,0,1,1.329,1.328V21.4h-25.2V19.6A1.33,1.33,0,0,1,84.056,18.267Zm-1.328,6.322V22.434h25.2v2.155Zm0,0"
                          transform="translate(-75.611)"
                        />
                        <path
                          id="Path_98"
                          data-name="Path 98"
                          d="M370.072,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791H373.2a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm2.885,2.516h-2.645V428.5h2.645Zm0,0"
                          transform="translate(-343.413 -398.064)"
                        />
                        <path
                          id="Path_99"
                          data-name="Path 99"
                          d="M233.053,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791h6.612a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm6.371,2.516h-6.131V428.5h6.131Zm0,0"
                          transform="translate(-215.819 -398.064)"
                        />
                        <path
                          id="Path_100"
                          data-name="Path 100"
                          d="M145.707,464.039a.516.516,0,0,0,0,1.032H149a.516.516,0,1,0,0-1.032Zm0,0"
                          transform="translate(-134.738 -432.118)"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="payment_right">
                    <div
                      onClick={() => {
                        setselected("elec");
                        setpaytype("elec");
                        setStatus(3);
                        dispatch(updatepaymentstatus({ paymentstatus: 3 }));
                      }}
                      className={
                        selected === "elec" ? 'payselect active' : 'payselect'
                      }
                    ></div>
                  </div>
                </div>
                <div className="down_payement" style={{ margin: "0 0 20px 0" }}>
                  {!minmalData?.hide_visa ? (
                    <>
                      <img
                        src="https://store.bst-inn.com/dashbord/uploads/about/16000875792829542.png"
                        alt=""
                      />

                      {/* {minmalData?.hide_stc ? ( */}
                      <img
                        src="https://store.bst-inn.com/dashbord/uploads/about/16000876147143221.png"
                        alt=""
                      />
                    </>
                  ) : null}
                  {/* ) :
                 null} */}

                  {!minmalData?.hide_mada ? (
                    <img
                      src="https://store.bst-inn.com/dashbord/uploads/about/16000877067523652.png"
                      alt=""
                    />
                  ) : null}
                  {!minmalData?.hide_stc ? (
                    <img
                      src="https://store.bst-inn.com/dashbord/uploads/about/16000877426682569.png"
                      alt=""
                    />
                  ) : null}
                </div>
              </>
            ) : null}
            {
              // payments.map((item, index) => {
              //   return (
              //     <div
              //       onClick={() => {
              //         dispatch(updateCartDataPayement({ way: item.title_en }));
              //       }}
              //       className="payment"
              //       style={{ flexDirection: "row-reverse" }}
              //     >
              //       <div className="payment_left">
              //         <h5>{language == 'ar' ? item.title : item.title_en}</h5>
              // <svg
              //   xmlns="http://www.w3.org/2000/svg"
              //   width="32.847"
              //   height="35.22"
              //   viewBox="0 0 32.847 35.22"
              // >
              //   <g id="credit" transform="translate(-0.5 0)">
              //     <path
              //       id="Path_85"
              //       data-name="Path 85"
              //       d="M36.449,20.027,20.509,35.966a1.844,1.844,0,0,1-2.608,0L8.522,26.587a1.844,1.844,0,0,1,0-2.608L24.461,8.04a1.845,1.845,0,0,1,2.608,0l9.38,9.38a1.845,1.845,0,0,1,0,2.608Zm0,0"
              //       transform="translate(-6.967 -6.984)"
              //       fill="#8cbafa"
              //     />
              //     <path
              //       id="Path_86"
              //       data-name="Path 86"
              //       d="M154.886,135.854l-1.171-1.171a1.752,1.752,0,0,1-.143,2.466l-15.939,15.939a1.752,1.752,0,0,1-2.465.143l1.17,1.171a1.844,1.844,0,0,0,2.608,0l15.939-15.939A1.845,1.845,0,0,0,154.886,135.854Zm0,0"
              //       transform="translate(-125.405 -125.419)"
              //       fill="#5692d8"
              //     />
              //     <path
              //       id="Path_87"
              //       data-name="Path 87"
              //       d="M216.376,61.93l-1.562,1.562a1.572,1.572,0,0,0,2.223,2.223l1.562-1.562a1.572,1.572,0,0,0-2.223-2.223Zm0,0"
              //       transform="translate(-199.143 -57.241)"
              //       fill="#ffcd34"
              //     />
              //     <path
              //       id="Path_88"
              //       data-name="Path 88"
              //       d="M113.581,258.051H91.04A1.844,1.844,0,0,0,89.2,259.9V273.16A1.844,1.844,0,0,0,91.04,275h22.541a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,113.581,258.051Zm0,0"
              //       transform="translate(-82.594 -240.3)"
              //       fill="#ffcd34"
              //     />
              //     <path
              //       id="Path_89"
              //       data-name="Path 89"
              //       d="M421.339,258.051h-1.651a1.844,1.844,0,0,1,1.844,1.844V273.16A1.844,1.844,0,0,1,419.688,275h1.651a1.844,1.844,0,0,0,1.844-1.844V259.9A1.844,1.844,0,0,0,421.339,258.051Zm0,0"
              //       transform="translate(-390.352 -240.3)"
              //       fill="#e69012"
              //     />
              //     <path
              //       id="Path_90"
              //       data-name="Path 90"
              //       d="M89.2,318.625h26.23v3.187H89.2Zm0,0"
              //       transform="translate(-82.594 -296.707)"
              //       fill="#575b7a"
              //     />
              //     <path
              //       id="Path_91"
              //       data-name="Path 91"
              //       d="M446.5,318.625h1.651v3.187H446.5Zm0,0"
              //       transform="translate(-415.316 -296.707)"
              //       fill="#252d4c"
              //     />
              //     <path
              //       id="Path_92"
              //       data-name="Path 92"
              //       d="M376.781,435.244v1.965a.275.275,0,0,0,.275.275h3.126a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-3.126A.275.275,0,0,0,376.781,435.244Zm0,0"
              //       transform="translate(-350.397 -405.048)"
              //       fill="#e9e9ea"
              //     />
              //     <path
              //       id="Path_93"
              //       data-name="Path 93"
              //       d="M239.762,435.244v1.965a.275.275,0,0,0,.275.275h6.612a.275.275,0,0,0,.275-.275v-1.965a.275.275,0,0,0-.275-.275h-6.612A.275.275,0,0,0,239.762,435.244Zm0,0"
              //       transform="translate(-222.803 -405.048)"
              //       fill="#e9e9ea"
              //     />
              //     <path
              //       id="Path_94"
              //       data-name="Path 94"
              //       d="M1.92,71.176a1.33,1.33,0,0,1,0-1.879L14.562,56.655a.516.516,0,0,0-.73-.73L1.19,68.568a2.363,2.363,0,0,0,0,3.338L4.374,75.09a.516.516,0,1,0,.73-.73Zm0,0"
              //       transform="translate(0 -51.938)"
              //     />
              //     <path
              //       id="Path_95"
              //       data-name="Path 95"
              //       d="M60.847,147.32l-7.807,7.807a.516.516,0,0,0,.73.73l7.807-7.807a.516.516,0,0,0-.73-.73Zm0,0"
              //       transform="translate(-48.785 -137.045)"
              //     />
              //     <path
              //       id="Path_96"
              //       data-name="Path 96"
              //       d="M208.944,59.722a2.082,2.082,0,0,0,1.477-.611l1.562-1.562a2.088,2.088,0,1,0-2.953-2.953l-1.562,1.562a2.087,2.087,0,0,0,1.477,3.564Zm-.747-2.834,1.562-1.562a1.056,1.056,0,0,1,1.494,1.494l-1.562,1.562a1.056,1.056,0,0,1-1.494-1.494Zm0,0"
              //       transform="translate(-192.161 -50.273)"
              //     />
              //     <path
              //       id="Path_97"
              //       data-name="Path 97"
              //       d="M106.6,17.235h-4.966l3.826-3.826a2.363,2.363,0,0,0,0-3.338L96.078.691a2.36,2.36,0,0,0-3.338,0L90.9,2.529a.516.516,0,0,0,.73.73l1.838-1.838a1.329,1.329,0,0,1,1.879,0l9.379,9.379a1.33,1.33,0,0,1,0,1.879l-4.556,4.556H87.133l3.75-3.75a.516.516,0,1,0-.73-.73l-4.48,4.48H84.056A2.363,2.363,0,0,0,81.7,19.6V32.86a2.363,2.363,0,0,0,2.36,2.36H106.6a2.363,2.363,0,0,0,2.36-2.36V30.953a.516.516,0,1,0-1.032,0V32.86a1.33,1.33,0,0,1-1.329,1.328H84.056a1.33,1.33,0,0,1-1.328-1.328V25.621h25.2v3.269a.516.516,0,1,0,1.032,0V19.6a2.363,2.363,0,0,0-2.36-2.36ZM84.056,18.267H106.6a1.33,1.33,0,0,1,1.329,1.328V21.4h-25.2V19.6A1.33,1.33,0,0,1,84.056,18.267Zm-1.328,6.322V22.434h25.2v2.155Zm0,0"
              //       transform="translate(-75.611)"
              //     />
              //     <path
              //       id="Path_98"
              //       data-name="Path 98"
              //       d="M370.072,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791H373.2a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm2.885,2.516h-2.645V428.5h2.645Zm0,0"
              //       transform="translate(-343.413 -398.064)"
              //     />
              //     <path
              //       id="Path_99"
              //       data-name="Path 99"
              //       d="M233.053,427.469a.792.792,0,0,0-.791.791v1.965a.792.792,0,0,0,.791.791h6.612a.792.792,0,0,0,.791-.791V428.26a.792.792,0,0,0-.791-.791Zm6.371,2.516h-6.131V428.5h6.131Zm0,0"
              //       transform="translate(-215.819 -398.064)"
              //     />
              //     <path
              //       id="Path_100"
              //       data-name="Path 100"
              //       d="M145.707,464.039a.516.516,0,0,0,0,1.032H149a.516.516,0,1,0,0-1.032Zm0,0"
              //       transform="translate(-134.738 -432.118)"
              //     />
              //   </g>
              // </svg>
              //         {/* <img src={item.paytypeimg} alt="" /> */}
              //       </div>
              //       <div className="payment_right">
              //         <div
              //           onClick={() => {
              //             setselected(item.id);
              //             setpaytype(item.title_en);
              //             dispatch(
              //               updateCartDataPayement({ way: item.title_en })
              //             );
              //           }}
              //           className={
              //             selected === item.id * 1
              //               ? 'payselect active'
              //               : 'payselect'
              //           }
              //         ></div>
              //         <img src={item.image} alt="" />
              //         {/* <img src={item?.payimg3} alt="" /> */}
              //       </div>
              //     </div>
              //   );
              // })
            }
            {showmodConFitnm ? (
              <div className="showmodvonf">
                <p>
                  {language == 'ar'
                    ? "هل أنت متأكد من استكمال الطلب؟"
                    : "Are you Sure Confirm Order"}
                </p>
                <div className="actions">
                  <button
                    onClick={() => {
                      handleaddorder();
                    }}
                  >
                    {language == 'ar' ? "نعم" : "Yes"}
                  </button>
                  <button
                    onClick={() => {
                      setshowmodConFitnm(false);
                    }}
                  >
                    {language == 'ar' ? "لا" : "No"}
                  </button>
                </div>
              </div>
            ) : null}
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader />
              </div>
            ) : (
              <button
                style={{ margin: 'auto', maxWidth: '100%' }}
                className="conpaybtn"
                onClick={() => {
                  if (paytype == "" || paytype == null) {
                    toast.warn(
                      language == 'ar'
                        ? "أختر طريقة الدفع"
                        : "choose a way of pay"
                    );
                    return;
                  }
                  dispatch(updateCartDataPayement({ way: paytype }));
                  // setshowmodConFitnm(true)
                  navigate("/talabaty");
                }}
              >
                تأكيد
              </button>
            )}
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default PaymentMethod;
