import React from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useSelector } from "react-redux";
import "./style.css";
import DefaultLayout from "../../layout/defaultLayout";
import { Input, InputGroup } from "rsuite";
import { Icon } from "@iconify/react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../CONSTS";
function ConfirmResetPassword() {
  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const location=useLocation();

  if(!location.state && !location.state.token){
    return <Navigate to="/login"/>
  }
  const {token} = location.state;



  const sendMessage = (e) => {
    const formData = new FormData(e.currentTarget);
    formData.append("password",e.currentTarget.mobile.value);
    formData.append("token", token);

    const options = {
        headers: {
          "Accept-Language": language
        }
      };
    axios
      .post(`${BASE_URL}api/web/v1/site/password-reset`, formData, options)
      .then((res) => {
        if (res?.data?.data[0]?.status) {
          navigate("/reset-passord-login");
          toast.success(res?.data?.data[0]?.message);
        } else {
          toast.error(res?.data?.data[0]?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <DefaultLayout
      children={
        <div className="auth login">
          <Brudcrumb
            title={language === "en" ? "Confirm Code To reset password" : "تأكيد كود لإعادة تعيين كلمة المرور"}
          />
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage(e);
            }}
          >
            <div className="inputField">
              <label htmlFor="mobile">
                {language === "ar" ? "الكود" : "code"}
              </label>
              <InputGroup>
                <Input type="text" name="mobile" />
              </InputGroup>
            </div>

            <button className="btn auth-btn">
              {language === "en" ? "Reset Password" : "إعادة تعيين كلمة المرور"}
            </button>
          </form>
          <ToastContainer />
        </div>
      }
    />
  );
}

export default ConfirmResetPassword;
