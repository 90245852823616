import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./branch.css";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const Brnach = ({
  branch,
  updateBranch,
  mylocdata,
  branch_zoom,
  setBranches,
  branch_zo,
  handleloc /* handlemyloc */,
}) => {
  const {
    address,
    closed_time,
    complaint_phone,
    delivery_phone,
    email,
    id,
    image,
    is_between_tow_day,
    lang,
    lat,
    mobile,
    name,
    name_en,
    open_time,
    region,
  } = branch;

  const language = useSelector((state) => state.language.lang);
  const mydate = new Date();

  useEffect(() => {
    closed_time.split(":", 1);
  }, []);

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers

    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c; // Distance in kilometers
    // console.log(
    //   'distance', lat1, lon1, lat2, lon2
    // );
    return distance;
  }

  const [distance, setDistance] = useState(false);
  const [closedOpen, setClosedOpen] = useState(false);
  const [latLng, setLatLng] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.watchPosition(
        // Success callback function
        function (position) {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          // console.log(lat)
          const lng = position.coords.longitude;
          // console.log(lng)
          setLatLng({ lat, lng });
          let loc = { lat, lng };
          const d = haversineDistance(
            lat,
            lng,
            branch.lat,
            branch.lang
          )?.toFixed(2);
          handleloc({ ...loc }, d);

          // handlemyloc(loc)
          // Update the map with the user's new location
        },
        // Error callback function
        function (error) {
          // Handle errors, e.g. user denied location sharing permissions
          // toast.error("Error getting user location");
        }
      );
    } else {
      // Geolocation is not supported by the browser
      toast.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (branch) {
      const d = haversineDistance(
        latLng.lat,
        latLng.lng,
        branch.lat,
        branch.lang
      )?.toFixed(2);
      setDistance(d);
      setBranches(branch?.id, d);
    }
  }, [latLng]);

  useEffect(() => {
    const currentTime = new Date();

    const desiredOpenTime = new Date(currentTime);
    desiredOpenTime.setHours(...open_time?.split(":"));

    const desiredCloseTime = new Date(currentTime);
    desiredCloseTime.setHours(...closed_time?.split(":"));

    const opened = currentTime >= desiredOpenTime;

    const closed = currentTime <= desiredCloseTime;

    setClosedOpen(opened && closed);
  }, [branch]);

  return (
    <div className="branch">
      <h5>{language === "ar" ? name : name_en}</h5>
      <div className="branch_work_time">
        <div className="clock">
          <AiOutlineClockCircle />
        </div>
        <h6>{language === "ar" ? " بداية الدوام " : "Shift beginning"}</h6>
        <h5>{open_time}</h5>
        <h6>{language === "ar" ? " نهاية الدوام " : "Shift end"}</h6>
        <h5>{closed_time}</h5>
      </div>

      <div className="actions">
        <div>
          {closedOpen ? (
            <button style={{ cursor: 'inherit' }}>
              {language === "ar" ? "مفتوح" : "Open"}
            </button>
          ) : (
            <button className="closed">
              {language === "ar" ? "مغلق" : "Closed"}
            </button>
          )}
          {isNaN(distance) ? (
            <h5>
              {language === "ar"
                ? "اسمح للموقع على المتصفح"
                : "Allow Location On Your Browser"}
            </h5>
          ) : (
            <span>
              {language === "ar"
                ? `${`يبعد عنك   ${distance}   كم`}`
                : `away from you   ${distance} KM`}
            </span>
          )}
        </div>
        <button
          onClick={() => {
            updateBranch(branch);
            branch_zoom();
          }}
        >
          {language === "ar" ? "تأكيد" : "confirm"}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Brnach;
