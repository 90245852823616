import React, { useEffect, useState } from "react";
import "./usersystem.css";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import DefaultLayout from "../../layout/defaultLayout";
import { useSelector } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import { get_towns } from "./functions";
import { SelectPicker } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../CONSTS";
const UserSystem = () => {
  const language = useSelector((state) => state.language.lang);
  const [towns, setTowns] = useState(false);


  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)

  const data = accessToken ? JSON.parse(accessToken) : null;

  const [updateloading,setupadteloading]=useState(false);

  const [usersystem,setusersystem]=useState({
    name:'',
    family_name:'',
    email:'',
    mobile:'',
    passwordupdate:'',
    city:'',
    region:'',
    address:'',
    file:'',
  })

  const handleupdate=()=>{
    setupadteloading(true);
    const formdata=new FormData();
    formdata.append("name",usersystem.name);
    formdata.append("family_name",usersystem.family_name);
    formdata.append("email",usersystem.email);
    formdata.append("mobile",usersystem.mobile);
    formdata.append("passwordupdate",usersystem.passwordupdate);
    formdata.append("city",usersystem.city);
    formdata.append("region",usersystem.region);
    formdata.append("address",usersystem.address);
    formdata.append("file",usersystem.file);
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    axios.post(`${BASE_URL}api/web/v1/site/profile?access-token=${data?.access_token}`,options)
    .then((res)=>{
      // console.log(res.data);
      if(res?.data?.data[0]?.status==1){
        toast.success(res?.data?.data[0]?.message);
      }
      else if(res?.data?.data[0]?.status==0){
        toast.error(res?.data?.data[0]?.message);
      }
      else {
        toast.error(language=="ar"?"حدث خطأ ما":"something went wrong");
      }
    }).catch(err=>{
      // console.log(err);
      toast.error(language=='ar'?"حدث خطأ فى الإتصال":"there are error in connection");
    }).finally(()=>{
      setupadteloading(false);
    })
  }




  const eqdata=()=>{
    var citydata=[];
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    get_towns({options}).then((res) => {
      // console.log(res)
      citydata=res.filter((item)=>item.id==data?.data?.city)
      // console.log(citydata)
    });
    // console.log(citydata);
    const mydata={
      name:data.data.username,
      family_name:data.data.name,
      email:data.data.email,
      mobile:data.data.mobile,
      passwordupdate:'',
      city:citydata[0]?.id,
      region:data.data.region,
      address:data.data.address,
      file:'',
    }
    
    get_towns({options}).then((res) => {
      // console.log(res)
      citydata=res.filter((item)=>item.id==data?.data?.city)
      mydata['city']=citydata[0].id;
    });
    setusersystem(mydata);
  }
  useEffect(() => {
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    get_towns({options}).then((res) => {
      setTowns(res);
    });
    eqdata();
  }, []);

  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb
            title={language === "ar" ? "إعدادات المستخدم" : "User System"}
          />
          <div className="pointnumber_div">
            <div className="pointnumber">
              <img src={require("../../assets/pointnumber.png")} alt="" />
              <h4>{language === "ar" ? "عدد النقاط" : "points number"}</h4>
            </div>
            <div>
              <h4>35</h4>
              <h3>{language === "ar" ? "نقطه" : "point"}</h3>
            </div>
          </div>
          <div className="contact_Form">
            <form
              onSubmit={(e)=>{
                e.preventDefault();
                handleupdate();
              }}
            >
              <div>
                <label htmlFor="img">
                  {language === "ar" ? "صورة المستخدم" : "user image"}
                </label>
                <input
                  onChange={(e)=>{
                    setusersystem({...usersystem,file:e.target.files[0]})
                  }}
                  id="img"
                  type="file"
                  placeholder={language === "ar" ? "إسم المستخدم" : "user name"}
                />
              </div>


              <div>
                <label htmlFor="name">
                  {language === "ar" ? "إسم المستخدم" : "user name"}
                </label>
                <input
                onChange={(e)=>{
                  setusersystem({...usersystem,name:e.target.value})
                }}
                value={usersystem.name}
                  id="name"
                  type="text"
                  placeholder={language === "ar" ? "إسم المستخدم" : "user name"}
                />
              </div>
              <div>
                <label htmlFor="name2">
                  {language === "ar" ? "إسم العائله" : "Name"}
                </label>
                <input
                onChange={(e)=>{
                  setusersystem({...usersystem,family_name:e.target.value})
                }}
                  id="name2"
                  type="text"
                  value={usersystem.family_name}
                  placeholder={language === "ar" ? "الإسم" : "name"}
                />
              </div>
              <div>
                <label htmlFor="email">
                  {language === "ar" ? "البريد الاإلكترونى" : "Email"}
                </label>
                <input
                onChange={(e)=>{
                  setusersystem({...usersystem,email:e.target.value})
                }}
                  id="email"
                  type="email"
                  value={usersystem.email}
                  placeholder={
                    language === "ar" ? "البريد الاإلكترونى" : "Email"
                  }
                />
              </div>
              <div>
                <label htmlFor="phone">
                  {language === "ar" ? "الجوال" : "Phone"}
                </label>
                <input
                onChange={(e)=>{
                  setusersystem({...usersystem,mobile:e.target.value})
                }}
                value={usersystem.mobile}
                  id="phone"
                  type="text"
                  placeholder={language === "ar" ? "الجوال" : "Phone"}
                />
              </div>
              <div>
                <label htmlFor="address">
                  {language === "ar" ? "العنوان التفصيلى" : "Address"}
                </label>
                <input
                onChange={(e)=>{
                  setusersystem({...usersystem,address:e.target.value})
                }}
                value={usersystem.address}
                  id="address"
                  type="text"
                  placeholder={
                    language === "ar" ? "العنوان التفصيلى" : "Address"
                  }
                />
              </div>
              {towns ? (
                <div style={{marginBottom:'10px'}}>
                  <label htmlFor="city">
                    {language === "ar" ? "المدينه" : "City"}
                  </label>
                  <div className="city_div">
                    <SelectPicker
                    id="city"
                    value={usersystem.city}
                    onChange={(e)=>{
                      setusersystem({...usersystem,city:e})
                    }}
                      style={{ width: "100%",marginTop:'30px' }}
                      data={towns.map((item) => {
                        return {
                          label: language === "ar" ? item.name : item.name_en,
                          value: item.id
                        };
                      })}
                      type="text"
                      placeholder={language === "ar" ? "المدينه" : "City"}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <label htmlFor="district">
                  {language === "ar" ? "الحى" : "District"}
                </label>
                <input
                value={usersystem.region}
                onChange={(e)=>{
                  setusersystem({...usersystem,region:e.target.value})
                }}
                  id="district"
                  type="text"
                  placeholder={language === "ar" ? "الحى" : "District"}
                />
              </div>
              <div>
                <label htmlFor="newpass">
                  {language === "ar" ? "كلمة المرور الجديده" : "New Password"}
                </label>
                <input
                  id="newpass"
                  onChange={(e)=>{
                    setusersystem({...usersystem,passwordupdate:e.target.value})
                  }}
                  type="password"
                  placeholder={language === "ar" ? "كلمة المرور الجديدة" : "new password"}
                />
              </div>
              <div>
                <label htmlFor="message">
                  {language === "ar"
                    ? "معلومات إضافيه"
                    : "additional information"}
                </label>
                <textarea
                  placeholder={
                    language === "ar"
                      ? "معلومات إضافيه"
                      : "additional information"
                  }
                  id="message"
                ></textarea>
              </div>
              <button>{language === "ar" ? "تعديل" : "Change"}</button>
            </form>
          </div>
        </>
      }
    />
  );
};

export default UserSystem;
