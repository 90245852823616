import React from "react";
import Signup from "../../pages/signup";
import MyAccount from "../../pages/myaccount";
import DownloadApps from "../../pages/appsdownload";
import Cart from "../../pages/cart";
import Talabaty from "../../pages/talabaty";
import ConfirmResetPassword from "../../pages/resetPassword";
import Reset_Password from "../../pages/Reset_Password/ResetPassword";
// import Branches from "../../components/branches";
import Conditions from "../../pages/conditions";
import UserSystem from "../../pages/UserSystem/UserSystem";
import ResetPassword from "../../pages/ResetPassword2/ResetPassword";
import MyAddress from "../../pages/MyAddresses/MyAddress";
import Branches from "../../pages/branches/branches/Branches";
import MyPurchases from "../../pages/MyPurchases/MyPurchases";
import WayToGetOrder from "../../pages/WayToGetOrder/WayToGetOrder";
import PaymentMethod from "../../pages/PaymentMethods/PaymentMethod";
import EditLocation from "../../pages/EditLocation/EditLocation";
const Home = React.lazy(() => import("../../pages/home"));
const Login = React.lazy(() => import("../../pages/login"));
const SendMessage = React.lazy(() => import("../../pages/sendmessage"));
const Verify_Account = React.lazy(() => import("../../pages/verifyAccount"));
const AddLocation = React.lazy(() =>
  import("../../pages/AddLocation/AddLocation")
);
const Contact = React.lazy(() => import("../../pages/Contact/Contact"));
const KnowUs = React.lazy(() => import("../../pages/KnowUs/Index"));
const OrderDetails = React.lazy(() =>
  import("../../pages/OrderDetails/OrderDetails")
);
const Offers = React.lazy(() => import("../../pages/offers/index"));
const Favoriest = React.lazy(() => import("../../pages/Favorites/index"));
export const routes = [
  {
    route: "/",
    component: <Home />
  },
  {
    route: "/login",
    component: <Login />
  },
  {
    route: "/sendmessage",
    component: <SendMessage />
  },
  {
    route: "/verifyaccount",
    component: <Verify_Account />
  },
  {
    route: "/signup",
    component: <Signup />
  },
  {
    route: "/myaccount",
    component: <MyAccount />
  },
  {
    route: "/cart",
    component: <Cart />
  },
  {
    route: "/confirmcoderesetpassword",
    component: <Reset_Password />
  },
  {
    route: "/reset-passord-login",
    component: <Reset_Password />
  },
  {
    route: "/condition",
    component: <Conditions />
  },
  {
    route: "/knowus",
    component: <KnowUs />
  },
  {
    route: "/addloc",
    component: <AddLocation />
  },

  {
    route: "/branches",
    component: <Branches />
  },
  {
    route: "/contact",
    component: <Contact />
  },
  {
    route: "/usersystem",
    component: <UserSystem />
  },
  {
    route: "/mypurchases",
    component: <MyPurchases />
  },
  // MyPurchases
  {
    route: "/resetpass",
    component: <ResetPassword />
  },
  {
    route: "/myaddresses",
    component: <MyAddress />
  },
  {
    route: "/orderdetails",
    component: <OrderDetails />
  },
  {
    route: "/waytogetorder",
    component: <WayToGetOrder />
  },
  {
    route: "/talabaty",
    component: <Talabaty />
  },
  {
    route: "/paymentmethods",
    component: <PaymentMethod />
  },
  {
    route: '/offers',
    component: <Offers />
  },
  {
    route: '/favorites',
    component: <Favoriest />
  },
  {
    route: '/downloadApps',
    component: <DownloadApps />
  },
  {
    route: '/editlocation',
    component: <EditLocation />
  }
  // confirmcoderesetpassword
];
