import React, { useEffect, useState } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import DefaultLayout from "../../layout/defaultLayout";
import { Input, InputGroup, Loader } from "rsuite";
import { Icon } from "@iconify/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import {
  emptycart,
  removeCartItem,
  updata_product_totalPrice,
  updateCartCopun,
  updateCartData,
  updateCartItem,
  updateCartNotes,
  updateCarttaxt,
} from "../../store/cartReducer";
import { BASE_URL } from "../../CONSTS";
function Cart() {
  const accessToken = localStorage.getItem("accessToken");
  const data = accessToken ? JSON.parse(accessToken) : null;
  const getBranch = useSelector((state) => state.branches.branch);

  let product = useSelector((s) => s.cart);
  // console.log(product, "dd");
  const [payments, setpayments] = useState([]);

  const [paytype, setpaytype] = useState("");
  const [delitype, setdelitype] = useState("");
  const [rateD, setRateD] = useState(false);
  const [checkedCode, setCheckedCode] = useState(false);
  const notesRef = useRef();

  const getpaymentmethods = () => {
    const options = {
      headers: {
        'Accept-Language': language, // Set the desired language code here
      },
    };
    axios.get(`${BASE_URL}api/web/v1/list/card-type`, options).then((res) => {
      setpayments(res.data.data[0].CardType);
    });
  };

  const [code, setCode] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const checkCode = (code, showtoast) => {
    if (code) {
      setCheckLoading(true);
      const options = {
        headers: {
          'Accept-Language': language, // Set the desired language code here
        },
      };
      const formData = new FormData();
      formData.append("code", code);
      for (const [key, value] of formData.entries()) {
        // console.log(`${key}: ${value}`);
      }
      axios
        .post(
          `${BASE_URL}api/web/v1/product/check-code?access-token=${data?.access_token}`,
          formData,
          options
        )
        .then((res) => {
          // console.log(res);
          setRateD(res.data.data[0].discount);
          setCheckedCode(code);
          if (res.data.data[0].status) {
            let priceAfD = product?.product_dishes_price
              ? parseFloat(product.cart_totalPrice) -
                product?.product_dishes_price
              : product.cart_totalPrice;
            let priceAfDis =
              priceAfD - priceAfD * (res.data.data[0].discount / 100);
            setDiscount(priceAfD * (res.data.data[0].discount / 100));
            dispatch(
              updata_product_totalPrice({
                price: product?.product_dishes_price
                  ? parseFloat(priceAfDis) + product?.product_dishes_price
                  : priceAfDis,
              })
            );
            product = { ...product, copun: code };
            dispatch(updateCartCopun({ copun: code }));
            dispatch(updateCartData());
            if (showtoast == true) {
              toast.success(res.data.data[0].message);
            }
          } else {
            if (showtoast == true) {
              toast.error(res.data.data[0].message);
            }
            setCode(false);
            setCheckedCode(false);
            setDiscount(0);
            product = { ...product, copun: null };
            dispatch(updateCartData());
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setCheckLoading(false);
        });
    }
    // } else {
    //   setCheckLoading(false);
    //   toast.error(
    //     language == "ar"
    //       ? "انت تستخدم كود خصم بالفعل"
    //       : "You used code previously"
    //   );
    // }
  };
  // console.log(product);
  useEffect(() => {
    if (product?.copun !== null) {
      setCheckedCode(product?.copun);
      checkCode(product?.copun, false);
      setCode(product?.copun);
    }
  }, []);
  useEffect(() => {
    getpaymentmethods();
  }, []);

  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const getUser = localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : false;
  const getCartItems = useSelector((state) => state.cart.data);
  const getCartData = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const [showmod, setshowmod] = useState(false);

  const [taxvalue, settaxvalue] = useState(0);
  const [minmalData, setMinimalData] = useState(false);
  const getminimal = () => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .get(`${BASE_URL}api/web/v1/product/order-limit`, options)
      .then((res) => {
        settaxvalue(res.data.data.tax);
        // console.log("MinimalData", res.data.data);
        setMinimalData(res.data.data);
      });
  };

  useEffect(() => {
    getminimal();
  }, []);

  const [loading, setloading] = useState(false);
  const [showConfmod, setShowConfmod] = useState(false);
  const [order_number, setOrderNumber] = useState(false);
  const handleaddorder = () => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("username", data?.data?.username);
    formdata.append("country", data?.data?.city);
    formdata.append("city_id", product?.city_id);
    formdata.append("city", product?.city_id);
    formdata.append("region", data?.data?.region);
    formdata.append("address", product?.address);
    formdata.append("mobile", data?.data?.mobile);
    formdata.append("description", product?.description);
    formdata.append("status", product?.paymentstatus);
    formdata.append("order_time", product?.order_time);
    formdata.append("branch_id", getBranch?.id);
    formdata.append("delivery_status", product?.waydeliv ? 0 : 1);
    formdata.append("totalprice", product?.totalPrice);
    formdata.append("number_of_items", product?.product_quantity);
    formdata.append("totalquantity", product?.totalquantity);
    formdata.append("product_ids", product?.product_ids);
    formdata.append("product_name", product?.product_name);
    formdata.append("product_price", product?.product_price);
    formdata.append("product_quantity", product?.product_quantity);
    formdata.append("product_extension", product?.product_extension);
    formdata.append("product_size", product?.product_size);
    formdata.append("product_rice", product?.product_rice);
    formdata.append("product_dish", product?.product_dish);
    formdata.append("product_totalPrice", product?.product_totalPrice);
    formdata.append("product_note", product?.product_note);
    formdata.append("lat", product?.lat);
    formdata.append("lang", product?.lang);
    if (code && code.length && product.copun) {
      formdata.append("copun", code);
    }
    // console.log("product?.product_rice", product?.product_rice);
    // alert(product?.product_rice)
    // for (var pair of formdata.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .post(
        `${BASE_URL}api/web/v1/product/checkout?access-token=${data?.access_token}`,

        formdata,
        options
      )
      .then((res) => {
        // console.log(res?.data?.data);
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          toast.success(res?.data?.data[0]?.message);
          /* hir */
          dispatch(emptycart());
          localStorage.removeItem("talabaty");

          // window.location.reload()
          setOrderNumber(res?.data?.data[0]?.order_number);
          setShowConfmod(true);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "something wen error");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
        setshowmod(false);
      });
  };
  useEffect(() => {
    checkCode(code, false);
  }, [getCartData, getCartItems]);
  const [showmodConFitnm, setshowmodConFitnm] = useState(false);
  useEffect(() => {
    if (
      product?.type == "" ||
      product?.type == null ||
      product?.type == undefined
    ) {
      setpaytype("");
    } else {
      setpaytype(product?.type);
    }

    if (
      product?.waydeliv !== null &&
      product?.waydeliv !== "" &&
      product?.waydeliv !== undefined
    ) {
      if (product?.waydeliv == 1) {
        setdelitype(1);
      } else if (product?.waydeliv == 0) {
        setdelitype(0);
      }
    } else {
      setdelitype("");
    }
    // console.log(product, "wd");
  }, []); //product?.type
  const handleOrderNavigate = () => {
    if (data?.access_token == null || data?.access_token == null) {
      toast.warn("سجل الدخول أولا");
      return;
    }
    axios.get(`${BASE_URL}api/web/v1/product/order-limit`).then((res) => {
      // console.log(res.data.data.tax)
      if (
        (product.waydeliv == null ||
          product.waydeliv == undefined ||
          product.waydeliv == "") &&
        product.waydeliv !== 0
      ) {
        navigate("/waytogetorder");
      }
      if (
        (res.data.data.order_limit <= product.totalPrice &&
          product?.waydeliv == 0) ||
        (res.data.data.order_limit_tasleem <= product.totalPrice &&
          product?.waydeliv == 1)
      ) {
        if (
          (product.waydeliv == null ||
            product.waydeliv == undefined ||
            product.waydeliv == "") &&
          product.waydeliv !== 0
        ) {
          navigate("/waytogetorder");
        }

        if (
          (product.waydeliv == 0 || product.waydeliv == 1) &&
          (product.type == null ||
            product.type == undefined ||
            product.type == "")
        ) {
          navigate("/paymentmethods");
        }
        if (
          (product.waydeliv != null ||
            product.waydeliv != undefined ||
            product.waydeliv != "") &&
          (product.type != null ||
            product.type != undefined ||
            product.type != "")
        ) {
          setshowmod(true);
        }
      } else {
        toast.error(
          language == 'ar' ? (
            <h4 style={{ display: 'flex', gap: '3px' }}>
              <span>أقل قيمة للشراء</span>
              <div style={{ display: 'flex', gap: '3px' }}>
                <span>
                  {product?.waydeliv == 0
                    ? res.data.data.order_limit
                    : res.data.data.order_limit_tasleem}
                </span>
                <span>ر.س</span>
              </div>
            </h4>
          ) : (
            <h4 style={{ display: 'flex', gap: '3px' }}>
              <span>The limit order is</span>
              <div style={{ display: 'flex', gap: '3px' }}>
                <span>
                  {product?.waydeliv == 0
                    ? res.data.data.order_limit
                    : res.data.data.order_limit_tasleem}
                </span>
                <span>SAR</span>
              </div>
            </h4>
          )
        );
      }
    });
  };
  if (showConfmod || showmod || showmodConFitnm) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  return (
    <DefaultLayout
      children={
        <div className="auth login cartItems">
          <Brudcrumb
            title={
              language === "ar"
                ? "تابع عملية شراء الطلب"
                : "Proceed to order purchase"
            }
          />
          {!getCartItems || !getCartItems.length ? (
            <div className="loginFirst">
              <div className="profileNotFound">
                <img
                  src={require("../../assests/icons/icon-cart.svg").default}
                  alt=""
                />
                <p>
                  {language === "ar" ? "سلة التسوق فارغة" : "Cart is empty"}
                </p>
              </div>
            </div>
          ) : (
            <div className="cartItems_container">
              <div className="product_text">
                <div className="products_card">
                  <div className="products_cards_container">
                    {getCartItems.map((item, index) => {
                      return (
                        <div
                          className="product_card"
                          style={{ border: ".5px solid #E2E2E2" }}
                          key={index}
                        >
                          <div>
                            <div className="product_image">
                              <img src={item.item.image} alt="" />
                            </div>
                            <div className="product_details">
                              <div className="product_text">
                                <div>
                                  <p style={{ fontSize: "22px" }}>
                                    {language === "ar"
                                      ? item.item.title
                                      : item.item.title_en}
                                  </p>
                                  <p style={{ fontSize: "17px" }}>
                                    {item?.selectedSize[0]?.size_name ? (
                                      <span>
                                        {language === "ar" ? "الحجم" : "Size"}{" "}
                                        {"   "}{" "}
                                        <span
                                          style={{
                                            color: "var(--main-color)",
                                            fontSize: "17px",
                                            display: "inline-block",
                                            margin: "0 10px",
                                          }}
                                        >
                                          <>
                                            <>
                                              {item?.selectedSize[0]?.size_name}{" "}
                                              ({" "}
                                              {parseInt(
                                                item?.selectedSize[0]?.dis_price
                                              )
                                                ? parseFloat(
                                                    item?.selectedSize[0]
                                                      ?.dis_price
                                                  )
                                                : parseFloat(
                                                    item?.selectedSize[0]?.price
                                                  )}{" "}
                                              SR )
                                            </>
                                          </>
                                        </span>
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                                <span
                                  className="discount"
                                  style={{
                                    padding: "5px",
                                    borderRadius: "4px",
                                    background: "#D31E4B",
                                    color: "white",
                                    width: "46px",
                                    height: "45px",
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    textAlign: "center",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      removeCartItem({
                                        cartProductId: item.cartProductId,
                                        // function: () => checkCode(code, false),
                                      })
                                    );
                                    dispatch(updateCartData());
                                  }}
                                >
                                  <img
                                    src={
                                      require("../../assests/icons/delete.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div
                                className="product_price_details"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div className="ext_c">
                                  <div className="extenxtion_c">
                                    {item.selectedExtensions &&
                                    item.selectedExtensions.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الإضافات"
                                            : "Extensions"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedExtensions.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.extension_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="extenxtion_c">
                                    {item.selectedDishes &&
                                    item.selectedDishes.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الأطباق"
                                            : "Dishes"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedDishes.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.dish_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="extenxtion_c">
                                    {item.selectedRice &&
                                    item.selectedRice.length ? (
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {" "}
                                          {language === "ar"
                                            ? "الأرز"
                                            : "Rice"}{" "}
                                        </h6>
                                        <ol>
                                          {item.selectedRice.map(
                                            (item, index) => {
                                              return (
                                                <li key={index}>
                                                  {" "}
                                                  {item?.rice_name} ({" "}
                                                  {parseInt(item?.dis_price)
                                                    ? parseFloat(
                                                        item?.dis_price
                                                      )
                                                    : parseFloat(
                                                        item?.price
                                                      )}{" "}
                                                  SR)
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    ) : null}
                                  </div>

                                  {item?.notes && item?.notes?.length ? (
                                    <span>
                                      {language === "ar"
                                        ? "الملاحظات"
                                        : "Notes"}{" "}
                                      {"   "}{" "}
                                      <span
                                        style={{
                                          color: "grey",
                                          fontSize: "13px",
                                          display: "inline-block",
                                          margin: "0 10px",
                                          whiteSpace: "pre-wrap",
                                        }}
                                      >
                                        {item?.notes}
                                      </span>
                                    </span>
                                  ) : null}
                                </div>
                                <div className="changePrice">
                                  {/* {console.log(item)} */}
                                  <div
                                    className="addPrice price_ch"
                                    onClick={() => {
                                      dispatch(
                                        updateCartItem({
                                          cartProductId: item.cartProductId,
                                          type: "increase",
                                          // function: () =>
                                          //   checkCode(code, false),
                                        })
                                      );
                                      dispatch(updateCartData());
                                    }}
                                  >
                                    +
                                  </div>
                                  <div className="quantity">
                                    {item.quantity}
                                  </div>
                                  <div
                                    className="decreasePrice price_ch"
                                    onClick={() => {
                                      dispatch(
                                        updateCartItem({
                                          cartProductId: item.cartProductId,
                                          type: "decrease",
                                          // function: () =>
                                          //   checkCode(code, false),
                                        })
                                      );
                                      dispatch(updateCartData());
                                    }}
                                  >
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="edit_in_cart">
                            <div className="changePrice">
                              {language == "ar" ? "السعر" : "Price"}{" "}
                              <span
                                style={{
                                  color: "var(--main-color)",
                                  fontSize: "17px",
                                  display: "inline-block",
                                  margin: "0 10px",
                                }}
                              >
                                SAR {"  "}
                                {item?.singlePrice?.toFixed(2)}
                              </span>
                            </div>
                            <div>
                              {language == "ar" ? "المجموع" : "Total price"}{" "}
                              <span
                                style={{
                                  color: "var(--main-color)",
                                  fontSize: "17px",
                                  display: "inline-block",
                                  margin: "0 10px",
                                }}
                              >
                                SAR {"  "}
                                {item?.totalPrice}
                              </span>
                            </div>
                            <ToastContainer />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="product_text">
                <div className="text_2">
                  <p className="with_underLine" style={{ fontSize: "16px" }}>
                    <span className="text_3">
                      {language == "ar" ? "ملاحظات" : "Notes"}
                    </span>
                  </p>
                </div>
                <div
                  className="inputs_p"
                  style={{
                    padding: "0 10px",
                    width: "100%",
                  }}
                >
                  <textarea
                    ref={notesRef}
                    placeholder={language == "ar" ? "ملاحظات" : "Notes"}
                    onChange={(e) => {
                      dispatch(updateCartNotes({ notes: e.target.value }));
                    }}
                    style={{
                      width: "100%",
                      minHeight: "147px",
                      border: ".5px solid#BBBBBB",
                      borderRadius: "5px",
                      padding: "10px",
                      outline: "none",
                    }}
                    name="notes"
                    id="notes"
                  />
                </div>
              </div>
              <div className="product_text product_text_c">
                <div className="text_2">
                  <p className="" style={{ fontSize: "16px" }}>
                    {paytype == "" ? (
                      <>
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/chooseway.png")}
                          alt=""
                        />
                        <span>
                          {language == "ar"
                            ? "تحديد طريقة الدفع "
                            : "Choose Payment Method"}
                        </span>
                      </>
                    ) : paytype == "cash" ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/pay1.png")}
                          alt=""
                        />
                        <span>
                          {language == "ar" ? "الدفع عن الاستلام كاش" : "Cash"}
                        </span>
                      </div>
                    ) : paytype == "mada" ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/pay1.png")}
                          alt=""
                        />
                        <span>
                          {" "}
                          {language == 'ar'
                            ? "الدفع عند الاستلام بطاقة مدي"
                            : "Pay With Mada"}
                        </span>
                      </div>
                    ) : paytype == "elec" ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/pay1.png")}
                          alt=""
                        />
                        <span>
                          {" "}
                          {language == 'ar'
                            ? "الدفع الالكتروني"
                            : "Electronic pay"}
                        </span>
                      </div>
                    ) : (
                      <div className="text_2">
                        {/* {console.log(delitype)} */}
                        <p className="" style={{ fontSize: "16px" }}>
                          <span className="text_3">
                            <img
                              style={{ width: '30px' }}
                              src={
                                require("../../assests/icons/payment-method.svg")
                                  .default
                              }
                              alt=""
                            />{" "}
                            <span>
                              {language == "ar"
                                ? "تحديد طريقة الدفع "
                                : "Choose Pay Way"}
                            </span>{" "}
                          </span>
                        </p>
                      </div>
                    )}
                  </p>
                </div>
                <div
                  onClick={() => {
                    if (
                      data?.access_token == null ||
                      data?.access_token == null
                    ) {
                      toast.warn("سجل الدخول أولا");
                      return;
                    }
                    navigate("/paymentmethods");
                  }}
                  className="inputs_p select_c"
                >
                  {language == "ar" ? "تغيير" : "Change"}
                </div>
              </div>
              <div className="product_text product_text_c">
                {!product?.waydeliv && product?.waydeliv * 1 !== 0 ? (
                  <div className="text_2">
                    {/* {console.log(delitype)} */}
                    <p className="" style={{ fontSize: "16px" }}>
                      <span className="text_3">
                        <img
                          style={{ width: '30px' }}
                          src={
                            require("../../assests/icons/delivery.svg").default
                          }
                          alt=""
                        />{" "}
                        <span>
                          {language == "ar"
                            ? "تحديد طريقة التوصيل "
                            : "Choose Delivery Way"}
                        </span>{" "}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div style={{ flex: '1' }} className="text_2">
                    {product?.waydeliv == 0 &&
                    product?.address &&
                    product?.address?.length ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/deliv.png")}
                          alt=""
                        />
                        <h4
                          style={{
                            fontSize: '17px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: '4px',
                          }}
                        >
                          {" "}
                          <span>
                            {/* {console.log("product", product)} */}
                            {language !== "ar"
                              ? "Delivery to address"
                              : "توصيل للعنوان"}
                            {" : "}
                            {product?.address}
                            {"  "}
                            <p style={{ color: "var(--main-color)" }}>
                              {language == "ar"
                                ? "سعر التوصيل : "
                                : "Delivary Price"}
                              ( {product?.delivery}
                              {"  "} SAR)
                            </p>
                          </span>{" "}
                        </h4>
                      </div>
                    ) : product?.waydeliv == 1 ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flex: '1',
                        }}
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/resturant.png")}
                          alt=""
                        />
                        <h4
                          style={{
                            display: 'flex',
                            flex: '1',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <span>
                            {language == 'ar'
                              ? 'فى المطعم'
                              : 'In The Resturant'}
                          </span>

                          <div
                            style={{
                              padding: '3px 10px',
                              margin: '0px',
                              flex: '1',
                            }}
                            className="alltimediv"
                          >
                            <div>
                              <img
                                src={require("../../assets/icon_sensor-alert(1).png")}
                                alt=""
                              />
                              <h4>
                                {language == 'ar'
                                  ? "وقت إستلام الطلب"
                                  : "Time To Get Order"}
                              </h4>
                            </div>

                            <div>
                              <h4>{product?.order_time}</h4>
                            </div>
                          </div>
                        </h4>
                      </div>
                    ) : product?.waydeliv == 0 && product?.address == "" ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                        className="product_text product_text_c"
                      >
                        <img
                          style={{ width: '30px' }}
                          src={require("../../assets/deliv.png")}
                          alt=""
                        />
                        <h4
                          style={{
                            fontSize: '17px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: '4px',
                          }}
                        >
                          {" "}
                          <span>
                            <p style={{ color: "var(--main-color)" }}>
                              {language == "ar"
                                ? "حدد العنوان"
                                : "Determine Location"}
                            </p>
                          </span>{" "}
                        </h4>
                      </div>
                    ) : null}
                  </div>
                )}
                <div
                  onClick={() => {
                    if (
                      data?.access_token == null ||
                      data?.access_token == null
                    ) {
                      toast.warn("سجل الدخول أولا");
                      return;
                    }
                    navigate("/waytogetorder");
                  }}
                  className="inputs_p select_c"
                >
                  {language == "ar" ? "تغيير" : "Change"}
                </div>
              </div>
              {!minmalData?.hide_cupon ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      borderBottom: "0px",
                    }}
                    className="product_text product_text_c"
                  >
                    <h4 htmlFor="" style={{ textAlign: "center" }}>
                      {language == "ar" ? " كود الخصم " : " Discount Code "}
                    </h4>
                  </div>
                  <div
                    style={{ marginBottom: '10px' }}
                    className="product_text product_text_c disc_text"
                  >
                    <div style={{ flex: '1' }} className="text_2">
                      <input
                        type="text"
                        style={{
                          padding: "10px 11px",
                          height: "100%",
                          fontSize: "19px",
                        }}
                        autoFocus
                        placeholder={
                          language == "ar" ? "أدخل كود الخصم" : "Enter Code"
                        }
                        value={code ? code : ""}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={() => {
                        if (
                          data?.access_token == null ||
                          data?.access_token == null
                        ) {
                          toast.warn("سجل الدخول أولا");
                          return;
                        }
                        return checkLoading ? null : checkCode(code, true);
                      }}
                      className="inputs_p select_c"
                    >
                      {checkLoading ? (
                        <Loader />
                      ) : (
                        <>
                          {" "}
                          {language == "ar"
                            ? "التحقق من كود الخصم"
                            : "Check code"}
                        </>
                      )}{" "}
                    </div>
                  </div>
                </>
              ) : null}

              <div
                style={{ margin: 'auto' }}
                className="edit_in_cart edit_in_cart_c"
              >
                <div style={{ margin: 'auto' }} className="changePrice">
                  {language == "ar" ? "المجموع" : "Total"}{" "}
                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px",
                    }}
                  >
                    SAR{" "}
                    {parseInt(getCartData?.product_all_totalPrice)
                      ? getCartData?.product_all_totalPrice?.toFixed(2)
                      : getCartData?.product_all_totalPrice}
                  </span>
                </div>
                <div style={{ margin: 'auto' }} className="changePrice">
                  {language == "ar" ? "الخصم" : "Discount"}
                  {checkedCode ? (
                    <span>
                      {language == "ar"
                        ? "الكود: " + checkedCode
                        : "Code:" + checkedCode}
                    </span>
                  ) : null}
                  {rateD ? (
                    <>
                      {" "}
                      {language == "ar"
                        ? "نسبة الخصم :" + rateD + "%"
                        : "Discount:" + rateD + "%"}
                    </>
                  ) : null}
                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px",
                    }}
                  >
                    SAR {"  "}
                    {discount ? discount?.toFixed(2) : 0}
                  </span>
                </div>
                <div style={{ margin: 'auto' }}>
                  {language == "ar" ? "سعر التوصيل" : "Delivery Price"}
                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px",
                    }}
                  >
                    SAR{"  "}{" "}
                    {getCartData?.delivery ? getCartData?.delivery : 0}
                  </span>
                </div>
                <div style={{ margin: 'auto' }}>
                  {language == "ar" ? " المجموع الكلي" : "Total Summation "}
                  {"  "}
                  {language == "ar"
                    ? "شامل ضريبة القيمة المضافة" + minmalData?.tax + "%"
                    : "With Vat " + minmalData?.tax + "%"}

                  <span
                    style={{
                      color: "var(--main-color)",
                      fontSize: "17px",
                      display: "inline-block",
                      margin: "0 10px",
                    }}
                  >
                    SAR {/* {console.log(discount)} */}
                    {parseInt(getCartData?.product_all_totalPrice)
                      ? (
                          parseFloat(
                            getCartData.product_all_totalPrice - discount
                          ) +
                          (parseInt(getCartData?.delivery) -
                          (parseInt(discount) ? parseFloat(discount) : 0)
                            ? parseFloat(getCartData?.delivery)
                            : 0)
                        ).toFixed(2)
                      : parseFloat(
                          getCartData?.product_all_totalPrice - discount
                        ) -
                        (parseInt(discount) ? parseFloat(discount) : 0) +
                        (parseInt(getCartData?.delivery)
                          ? parseFloat(getCartData?.delivery)
                          : 0)}
                  </span>
                </div>
              </div>
              <div className="order_btns">
                {!loading ? (
                  <button
                    className="continue_o"
                    onClick={() => {
                      handleOrderNavigate();
                    }}
                  >
                    {language == "ar" ? "استمرار" : "Continue"}
                  </button>
                ) : (
                  <Loader />
                )}
                <button className="add_more_o" onClick={() => navigate("/")}>
                  {language == "ar" ? "أضف المزيد" : "Add More"}
                </button>
              </div>
            </div>
          )}
          {showmodConFitnm ? (
            <div className="showmodvonf">
              <p>
                {language == 'ar'
                  ? "هل أنت متأكد من استكمال الطلب؟"
                  : "Are you Sure Confirm Order"}
              </p>
              <div className="actions">
                {!loading ? (
                  <button
                    onClick={() => {
                      handleaddorder();
                    }}
                  >
                    {language == 'ar' ? "نعم" : "Yes"}
                  </button>
                ) : (
                  <Loader />
                )}
                <button
                  onClick={() => {
                    setshowmodConFitnm(false);
                    setshowmod(false);
                  }}
                >
                  {language == 'ar' ? "لا" : "No"}
                </button>
              </div>
            </div>
          ) : null}
          {showmod ? (
            <div className="showmodvonf">
              <p>
                {language == 'ar'
                  ? "هل أنت متأكد من بيانات التوصيل والدفع الحاليه؟"
                  : "Are you Sure From Current Payment Methods And Delivery Data"}
              </p>
              <div className="actions">
                {!loading ? (
                  <button
                    onClick={() => {
                      setshowmodConFitnm(true);
                      setshowmod(false);
                    }}
                  >
                    {language == 'ar' ? "نعم" : "Yes"}
                  </button>
                ) : (
                  <Loader />
                )}
                <button
                  onClick={() => {
                    navigate("/waytogetorder");
                  }}
                >
                  {language == 'ar' ? "لا" : "No"}
                </button>
              </div>
            </div>
          ) : null}

          {showConfmod ? (
            <div className="showmodvonf order_conf">
              <p>
                {language == 'ar'
                  ? "تم تأكيد الطلب بنجاح"
                  : "Order Confirmed Successfully"}
              </p>
              <span>
                <svg
                  id="receipt"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.389"
                  height="21.852"
                  viewBox="0 0 31.389 41.852"
                >
                  <path
                    id="Path_670"
                    data-name="Path 670"
                    d="M25.67,0H11.719A8.73,8.73,0,0,0,3,8.719V40.108a1.744,1.744,0,0,0,2.727,1.439L9.4,39.037l3.672,2.509a1.744,1.744,0,0,0,1.971,0L18.7,39.037l3.662,2.509a1.744,1.744,0,0,0,1.972,0L28,39.039l3.662,2.506a1.744,1.744,0,0,0,2.726-1.437V8.719A8.73,8.73,0,0,0,25.67,0ZM30.9,36.795l-1.918-1.311a1.744,1.744,0,0,0-1.974,0l-3.662,2.509-3.662-2.509a1.744,1.744,0,0,0-1.972,0l-3.662,2.509-3.662-2.509a1.744,1.744,0,0,0-1.969,0L6.488,36.795V8.719a5.231,5.231,0,0,1,5.231-5.231H25.67A5.231,5.231,0,0,1,30.9,8.719Z"
                    transform="translate(-3)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_213"
                    data-name="Rectangle 213"
                    width="17.842"
                    height="3.568"
                    rx="1"
                    transform="translate(6.41 14.274)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_214"
                    data-name="Rectangle 214"
                    width="14.274"
                    height="3.568"
                    rx="1"
                    transform="translate(6.41 21.411)"
                    fill="#fff"
                  />
                </svg>

                <span> {language == 'ar' ? "رقم الطلب" : "Order Number"}</span>
                <span> {order_number} </span>
              </span>
              <div className="actions order_act">
                <button
                  onClick={() => {
                    setshowmodConFitnm(false);
                    setshowmod(false);
                    setShowConfmod(false);

                    window.location.href = "/mypurchases";
                  }}
                >
                  {language == 'ar' ? "تابع طلبك" : "Follow Your Order"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      }
    />
  );
}

export default Cart;
