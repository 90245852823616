import React, { useState } from 'react'
import Brudcrumb from '../../components/brudcrumb/brudcrumb'
import DefaultLayout from '../../layout/defaultLayout'
import { useSelector } from 'react-redux'
import { BsEye } from 'react-icons/bs'
import './resetpassword.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../CONSTS'
const ResetPassword = () => {
  const language = useSelector((state) => state.language.lang);
  const navigate=useNavigate();

  const [confpass,setconfpass]=useState("");
  const [password,setpassword]=useState("");

  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)

  const data = accessToken ? JSON.parse(accessToken) : null;

  const handlechangepass=()=>{
// console.log((confpass , password));
    if(confpass !== password){
      toast.warn(language=="ar"?"يجب ان تكون كلمة المرور مشابهه لكلمة التأكيد":"confirm password should similar to password");
      return;
    }

    const formdata=new FormData();
    formdata.append("token",data?.access_token);
    formdata.append("password",password);
    axios.post(`${BASE_URL}api/web/v1/site/reset-password`,formdata)
    .then((res)=>{
      // console.log(res.data);
      if(res.data.status==0){
        toast.error(res?.data?.data[0]?.message);
      }
      else if(res?.data?.data[0]?.status==0){
        toast.error(res?.data?.data[0]?.message);
      }
      else if(res?.data?.data[0]?.status==1){
        toast.success(res?.data?.data[0]?.message);
        navigate("/login")
      }
    })
  }

  return (
    <DefaultLayout children={
      <>
        <Brudcrumb title={language==='ar'?'تغيير كلمة المرور':'Reset Passwrod'}/>
        <div className="contact_Form">
          <form
            onSubmit={(e)=>{
              e.preventDefault();
              handlechangepass();
            }}
          >
          {/* <div>
            <label htmlFor="oldpass">{language==='ar'?'كلمة المرور القديمة':'Old Password'}</label>
              <div className='showpass'>
                <input id='oldpass' type="text" placeholder={language==='ar'?'كلمة المرور القديمة':'Old Password'}/>
                <BsEye/>
              </div>
          </div> */}
          <div>
            <label htmlFor="newpass">{language==='ar'?'كلمة المرور الجديده':'New Password'}</label>
              <div className='showpass'>
                <input onChange={(e)=>{
                  setpassword(e.target.value)
                }} id='newpass' type="password" placeholder={language==='ar'?'كلمة المرور الجديده':'New Password'}/>
                <BsEye/>
              </div>
          </div>
          <div>
            <label htmlFor="confpass">{language==='ar'?' تأكيد كلمة المرور':'Confirm Password'}</label>
              <div className='showpass'>
                <input onChange={(e)=>{
                  setconfpass(e.target.value)
                }}  id='confpass' type="password" placeholder={language==='ar'?'تأكيد كلمةالمرور':'}Confirm Password'}/>
                <BsEye/>
              </div>
          </div>
            <button className='resset-btn'>حفظ التغييرات</button>
          </form>
        </div>
      </>
    } />
  )
}

export default ResetPassword
