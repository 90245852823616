import React, { useState } from "react";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import DefaultLayout from "../../layout/defaultLayout";
import { useSelector } from "react-redux";
import { BsEye } from "react-icons/bs";
import "./resetpassword.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Input, InputGroup } from "rsuite";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../CONSTS";
const Reset_Password = () => {
  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [confcode,setconfcode]=useState("");
  const [newpass,setnewpass]=useState("");
  const location = useLocation();
  const sendMessage = (e) => {
    const formData = new FormData(e.currentTarget);
    formData.append("password", newpass);
    formData.append("mobile", location?.state);
    formData.append("token",confcode);
    // console.log(formData)
    const options = {
      headers: {
        "Accept-Language": language
      }
    };
    // console.log(formData)
    axios
      .post(
        `${BASE_URL}api/web/v1/site/reset-password`,
        formData,
        options
      )
      .then((res) => {
        if (res?.data?.data[0]?.status) {
          navigate("/login");
          toast.success(res?.data?.data[0]?.message);
        } else {
          toast.error(res?.data?.data[0]?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb
            title={
              language === "ar" ? "كود إعادة تعيين كلمة المرور" : "Reset Passwrod Code"
            }
          />
          <div className="contact_Form">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="confcod">
                  {language === "ar"
                    ? "كود التأكيد"
                    : "Confirm Code"}
                </label>
                <InputGroup>
                  <Input
                    onChange={(e)=>{
                      setconfcode(e)
                    }}
                    id="confcod"
                    type={"text"}
                    name="token"
                    placeholde={
                      language === "ar" ? "كود إعادة تعيين كلمة المرور" : "Code"
                    }
                  />
                </InputGroup>
              </div>
              {/* <div className="inputField">
                <label htmlFor="password">
                  {language === "ar"
                    ? "إعادة تعيين كلمة المرور"
                    : "Reset Password"}
                </label>
                <InputGroup>
                  <Input
                    type={"text"}
                    name="token"
                    placeholde={
                      language === "ar" ? "كود إعادة تعيين كلمة المرور" : "Code"
                    }
                  />
                </InputGroup>
              </div> */}

              <div className="inputField">
                <label htmlFor="password">
                  {language === "ar" ? " كلمة المرور الجديدة" : "New Password"}
                </label>
                <InputGroup>
                  <Input
                    onChange={(e)=>{
                      setnewpass(e);
                    }}
                    type={!showPass ? "password" : "text"}
                    name="password"
                    placeholde={
                      language === "ar"
                        ? " كلمة المرور الجديدة"
                        : "New Password"
                    }
                  />
                  <InputGroup.Addon
                    onClick={() => setShowPass(!showPass)}
                    style={{
                      cursor: "pointer",
                      background: "transparent",
                      color: "var(--main-color)"
                    }}
                  >
                    <Icon icon="carbon:view" />
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              <button style={{ margin:'0px' }} className="resset-btn">
                {language === "ar" ? "تغيير كلمة المرور " : "Change Password"}
              </button>
            </form>
          </div>
        </>
      }
    />
  );
};

export default Reset_Password;
