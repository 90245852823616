import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./address.css";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../CONSTS";
import { useNavigate } from "react-router-dom";
const Address = ({
  address,
  setOpenEdit,
  setItem,
  setOneDelete,
  handleeqdata,
  handleupdate,
}) => {
  const accessToken = localStorage.getItem("accessToken");

  const data = accessToken ? JSON.parse(accessToken) : null;
  const navigate=useNavigate();
  const language = useSelector((state) => state.language.lang);
  const { city, id, is_default, lang, late, map_location, name, state } =
    address;
  // console.log(address)
  const [delloading, setdelloading] = useState(false);
  const handledelete = () => {
    const formdata = new FormData();
    formdata.append("id", id);
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .post(
        `${BASE_URL}api/web/v1/product/delete-address?access-token=${data?.access_token}`,
        formdata,
        options
      )
      .then((res) => {
        setdelloading(true);
        // handleloading(true);
        // console.log(res)
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          toast.success(res?.data?.data[0]?.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setdelloading(false);
        // handleupdate();
        // handleloading(false);
      });
  };
  return (
    <div className="address">
      <div className="address_right">
        <h4>{address.address}</h4>
        <h4>{city.name}</h4>

        {is_default == 0 ? null : (
          <button>{language === "ar" ? "الافتراضي" : "default"}</button>
        )}
      </div>
      <div className="address_left">
        <button
          className="edit"
          onClick={() => {
            // setOpenEdit(true);
            // setItem(address);
            // handleeqdata(address);
            navigate("/editlocation",{state:{oldData:address}})
          }}
        >
          <img src={require("../../../assets/edit.png")} alt="" />
          <span>{language === "ar" ? "تعديل" : "edit"}</span>
        </button>
        <button
          className="delete"
          onClick={() => {
            setOneDelete(true);
            setItem(address);
          }}
        >
          <img src={require("../../../assets/trash.png")} alt="" />
          <span>{language === "ar" ? "حذف" : "delete"}</span>
        </button>
      </div>
    </div>
  );
};

export default Address;
