import { Fragment, Suspense, useEffect } from "react";
import "./App.css";
import RoutesC from "./data/routes/routes";
import "rsuite/dist/rsuite.min.css";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";

import ToastContainer from "rsuite/esm/toaster/ToastContainer";
import "react-slideshow-image/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-awesome-slider/dist/styles.css";
import axios from "axios";
import { useState } from "react";
import "./layout/header/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "./CONSTS";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  const [data, setdata] = useState({});
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(accessToken);
  const language = useSelector((state) => state.language.lang);
  if (language == "ar") {
    document.body.classList.add("ar");
  } else {
    document.body.classList.remove("ar");
  }
  const getthemsdata = () => {
    axios
      .get(`${BASE_URL}api/web/v1/product/style`, {
        headers: {
          "Content-type": "application/json; charset=utf-8",
          Accept: "application/json; charset=utf-8",
          "Accept-Language": language == "ar" ? "ar" : "en",
        },
      })
      .then((res) => {
        setdata(
          res?.data?.data && res?.data?.data?.length ? res?.data?.data[0] : null
        );
      });
  };
  useEffect(() => {
    getthemsdata();
  }, []);

  useEffect(() => {
    document.title = data?.sitname ? data?.sitname : "الركن الذهبي";
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style>:root{--main-color:${data?.mainColor}}</style>`
    );
    document.head.insertAdjacentHTML(
      "beforeend",
      `<link rel="icon" href=${data?.logo} />
      <link rel="apple-touch-icon" href=${data?.logo} />
      `
    );
  }, [data]);
  const options = {
    headers: {
      "Accept-Language": language,
    },
  };
  const getNewUserData = async () => {
    const getUser = await axios.get(
      `${BASE_URL}api/web/v1/product/user-info?access-token=${userData?.access_token}`,
      options
    );
    if (getUser?.data?.data && getUser?.data?.data?.length) {
      userData.data = getUser?.data?.data[0]?.model;
      localStorage.setItem("accessToken", JSON.stringify(userData));
    }
  };
  useEffect(() => {
    getNewUserData();
  }, []);
  const getBranch = useSelector((state) => state.branches.branch);
  const navigate = useNavigate();

  const location = useLocation();

  if (!Object.keys(getBranch)?.length && location.pathname != "/branches") {
    return navigate("/branches");
  }

  return (
    <Fragment>
      <Suspense fallback={<Loader size="md" />}>
        <>
          <RoutesC />
        </>
      </Suspense>
    </Fragment>
  );
}

export default App;
