import {combineReducers} from "@reduxjs/toolkit";
import languageReduces from "./languageReduces";
import branchReducer from "./branchReducer";
import cartReducer from "./cartReducer";
import SiteDataSlice from './SiteData';
export const rootReducers = combineReducers({
  language: languageReduces,
  branches: branchReducer,
  cart: cartReducer,
  SiteData:SiteDataSlice
});
