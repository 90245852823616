import React, { useEffect, useState } from "react";
import "./myaddress.css";
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import DefaultLayout from "../../layout/defaultLayout";
import { useSelector } from "react-redux";
import "./myaddress.css";
import { Button, Input, InputGroup, Loader, Modal } from "rsuite";
import axios from "axios";
import Address from "./Address/Address";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import EditAddressForm from "./editlocation";
import "../AddLocation/style.css";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { BASE_URL } from "../../CONSTS";
import { get_towns } from "../UserSystem/functions";
const MyAddress = () => {
  const language = useSelector((state) => state.language.lang);
  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)
  const options = {
    headers: {
      "Accept-Language": language,
    },
  };
  const [towns, setTowns] = useState(false);
  useEffect(() => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    get_towns({ options }).then((res) => {
      setTowns(res);
    });
    setOpenEdit(false)
  }, [language]);
  const data = JSON.parse(accessToken);
  const navigate = useNavigate();
  const [addresses, setaddresses] = useState(null);

  const [delloading, setdelloading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, sethasNextPage] = useState(true);

  const getaddresses = () => {
    // console.log(data?.access_token);
    if (!data?.access_token) return setaddresses([]);

    axios
      .get(
        `${BASE_URL}api/web/v1/product/address?access-token=${data?.access_token}&page=${page}`,
        options
      )
      .then((res) => {
        let newarr = [];
        // newarr=res?.data?.data[0]?.items.sort();

        setaddresses(
          addresses && addresses.length
            ? [...addresses, ...res?.data?.data[0]?.items]
            : [...res?.data?.data[0]?.items]
        );
        sethasNextPage(page < res.data.data[0]?._meta?.NumberOfPage);
        // console.log(res?.data?.data[0]?.items)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getaddresses();
  }, [page]);
  const [openEdit, setOpenEdit] = useState(false);
  const [item, setItem] = useState(false);
  const [onDelete, setOneDelete] = useState(false);

  const handleloading = (status) => {
    setdelloading(status);
  };

  const handledelete = (id) => {
    // console.log(id)
    setdelloading(true);
    setOpenEdit(false);
    const formdata = new FormData();
    formdata.append("id", id);
    // console.log(formdata)

    axios
      .post(
        `${BASE_URL}api/web/v1/product/delete-address?access-token=${data?.access_token}`,
        formdata,
        options
      )
      .then((res) => {
        setdelloading(true);
        handleloading(true);
        // console.log(res.data.data)
        if (res?.data?.data[0]?.status == 0) {
          toast.error(res?.data?.data[0]?.message);
        } else if (res?.data?.data[0]?.status == 1) {
          toast.success(res?.data?.data[0]?.message);
          getaddresses();
          window.location.reload();
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setdelloading(false);
        handleloading(false);
      });
  };

  const [editaddressdata, seteditaddressdata] = useState({});

  const handleeqdata = (address) => {
    seteditaddressdata(address);
  };

  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb title={language === "ar" ? "عناوينى" : "My Addresses"} />
          {addresses ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  width: "500px",
                  maxWidth: "100%",
                  margin: "20px auto",
                }}
              >
                {language === "ar" ? "العناوين" : "Addresses"}
              </h5>
              <h4>
                {delloading ? (
                  <span>{language == "ar" ? "إنتظار..." : "Loading...."}</span>
                ) : null}
              </h4>
            </div>
          ) : null}
          {!addresses ? (
            <Loader />
          ) : addresses.length ? (
            <div
              className="my_addresses"
              style={{ textAlign: "center", margin: "auto" }}
            >
              <InfiniteScroll
                dataLength={addresses.length}
                loader={<Loader />} //This is important field to render the next data
                next={() => {
                  if (hasNextPage) setPage((prev) => prev + 1);
                }}
                hasMore={hasNextPage}
                // below props only if you need pull down functionality
                refreshFunction={() => {
                  if (hasNextPage) setPage((prev) => prev + 1);
                }}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
              >
                {addresses?.length > 0 ? (
                  addresses?.map((item, index) => {
                    return (
                      <Address
                        handleeqdata={handleeqdata}
                        // handleloading={handleloading}
                        address={item}
                        setOpenEdit={setOpenEdit}
                        setOneDelete={setOneDelete}
                        setItem={setItem}
                        handleupdate={() => {
                          getaddresses();
                        }}
                      />
                    );
                  })
                ) : (
                  <Fragment>
                    <h5
                      style={{
                        color: "var(--main-color)",
                        margin: "20px auto",
                      }}
                    >
                      لا يوجد عناوين
                    </h5>
                    <br />
                  </Fragment>
                )}
              </InfiniteScroll>
              <div className="addnewloc">
                <button
                  className="addnewloc-btn"
                  onClick={() => navigate("/addloc")}
                >
                  {language === "ar" ? " إضافة عنوان جديد " : "Add New Address"}
                </button>
              </div>
            </div>
          ) : data?.access_token && data?.access_token?.length ? (
            <div className="addnewloc">
              <button
                className="addnewloc-btn"
                onClick={() => navigate("/addloc")}
              >
                {language === "ar" ? " إضافة عنوان جديد " : "Add New Address"}
              </button>
            </div>
          ) : (
            <div className="addnewloc">
              <button
                style={{ margin: "20px 0" }}
                className="addnewloc-btn"
                onClick={() => navigate("/login")}
              >
                {language === "ar" ? "سجل الدخول أولا" : "Login First"}
              </button>
            </div>
          )}
          <Modal
            className="modal_p"
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            // dir={language === "ar" ? "rtl" : "ltr"}
          >
            <Modal.Header>
              <Modal.Title>
                {language === "ar" ? "تعديل العنوان" : "Edit Address"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit_loc">
              <EditAddressForm
                handlechange={() => {
                  setOpenEdit(false);
                }}
                towns_2={towns}
                oldData={item}
              />
            </Modal.Body>
          </Modal>
          <Modal
            className="modal_p"
            open={onDelete}
            onClose={() => setOneDelete(false)}
          >
            <Modal.Header>
              <Modal.Title>
                {language === "ar" ? "حذف العنوان" : "Delete Address"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{ textAlign: "center" }}>
                {language === "ar"
                  ? "هل أنت متأكد من حذف العنوان "
                  : "Are you sure to delete Address"}
              </h5>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handledelete(item.id);
                  setOneDelete(false);
                }}
                appearance="primary"
              >
                Ok
              </Button>
              <Button onClick={() => setOneDelete(false)} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }
    />
  );
};

export default MyAddress;
