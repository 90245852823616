import React from "react";
import { routes } from "./route";
import { Routes, Route } from "react-router-dom";
const Home = React.lazy(() => import("../../pages/home"));
function RoutesC() {
  return (
    <Routes>
      {routes.map((item, index) => {
        return <Route path={item.route} element={item.component} key={index} />;
      })}
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default RoutesC;
