import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api';
import { Loader } from 'rsuite';
import './branch.css';
const containerStyle = {
  width: "100%",
  height: "500px"
};

function MyComponent({ getBranch, branch_zo, branches, branch_d }) {
  // console.log(branch_d)
  const [center, setCenter] = useState({
    lat: 26.38206397995671,
    lng: 50.055014076454185
  });
  const [zoom, setzoom] = useState(1);
  // const bounds = new window.google.maps.Map
  // console.log(bounds,"fefe")

  useEffect(() => {
    if (branch_d.lat != undefined) {
      setCenter({
        lat: branch_d ? +branch_d?.lat : +branches[0]?.lat,
        lng: branch_d ? +branch_d?.lang : +branches[0]?.lang
      });
    } else {
      setCenter({
        lat: getBranch[0] ? +getBranch[0]?.lat : +branches[0]?.lat,
        lng: getBranch[0] ? +getBranch[0]?.lang : +branches[0]?.lang
      });
    }
  }, [getBranch, branches, branch_d]);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBaUmZqLrBQj3LQHChTJOHJuqvDbg_lbAU"
  });

  const [map, setMap] = React.useState(null);
  const [sourceLoc, setSourceLocation] = useState({});

  const onLoad = React.useCallback(
    function callback(map) {
      // const bounds = new window.google.maps.LatLngBounds(center);
      // map.fitBounds(bounds);
      map.setZoom(10);
      setMap(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleZoomChange = (newZoom) => {
    setzoom(newZoom);
  };

  useEffect(() => {
    // console.log("center", center);
  }, [center]);
  useEffect(() => {
    // setzoom(12)
    // handleZoomChange(4)
  }, []);
  useEffect(() => {
    setzoom(branch_zo);
  }, [branch_zo]);
  return isLoaded &&
    branches &&
    branches?.length &&
    center?.lat &&
    center?.lng ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        // maxZoom={13}
        zoom={zoom}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {  branches &&
          branches.map((item) => {
            // console.log("item", item);
            return (
              <Marker
                position={{
                  lat: parseFloat(item?.lat),
                  lng: parseFloat(item?.lang)
                }}
              />
            );
          })}
        <div className="zoominout">
          <button
            style={{ width: '30px' }}
            onClick={() => {
              handleZoomChange(zoom < 23 ? zoom + 1 : zoom);
            }}
          >
            +
          </button>
          <button
            style={{ width: '30px' }}
            onClick={() => handleZoomChange(zoom > 1 ? zoom - 1 : zoom)}
          >
            -
          </button>
        </div>
      </GoogleMap>
    </>
  ) : (
    <>
      <Loader />
    </>
  );
}

export default React.memo(MyComponent);
