import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import { AppData } from '../../constants'
import Brudcrumb from "../../components/brudcrumb/brudcrumb";
import DefaultLayout from "../../layout/defaultLayout";
// import { useTranslation } from 'react-i18next'
import "./style.css";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../CONSTS";
const Conditions = () => {
  // const {t,i18n}=useTranslation()
  const titleref = useRef();
  const contentref = useRef();
  const language = useSelector((state) => state.language.lang);

  const [condition, setcondition] = useState([]);
  const [data, setData] = useState(false);

  const getconditions = async () => {
    await axios
      .get(`${BASE_URL}api/web/v1/site/term`, {
        headers: {
          'Content-type': 'application/json; charset=utf-8',
          'Accept': 'application/json; charset=utf-8',
          'Accept-Language': language == "ar" ? "ar" : "en"
       }
      })
      .then((res) => {
        setcondition(res.data.data);
        setData(res.data.data);
      }).catch(err=>console.log(err));
  };
  useEffect(() => {
    getconditions();
  }, [language]);
  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb
            title={
              language === "ar" ? "الشروط والاحكام" : "Terms and Conditions"
            }
          />
          {condition.map((item, index) => {
            return (
              <div style={{ padding:'10px' }} className="conditions">
                {/* {item.title.innerHTML} */}
                {data
                  ? data.map((item, index) => {
                      return (
                        <div>
                          <h6>{item.title}</h6>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          ></p>
                        </div>
                      );
                    })
                  : null}
              </div>
            );
          })}

        </>
      }
    />
  );
};

export default Conditions;
