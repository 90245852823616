import React, { useEffect, useState } from "react";
import "./branches.css";
import Brudcrumb from "../../../components/brudcrumb/brudcrumb";
import DefaultLayout from "../../../layout/defaultLayout";
import axios from "axios";
import Brnach from "./Branch/Brnach";
import { useDispatch, useSelector } from "react-redux";
import { updateBranch } from "../../../store/branchReducer";
import moment from "moment";
import { toast } from "react-toastify";
import {
  deletecart,
  resetcart,
  updateCartData,
} from "../../../store/cartReducer";
import BranchMap from "./Branch/branchMap";
import { BASE_URL } from "../../../CONSTS";
const Branches = () => {
  const getBranch = useSelector((state) => state.branches.branch);
  // console.log(getBranch);
  const [branches, setbranches] = useState([]);
  const [showconf, setshowconf] = useState(false);
  // console.log("ff")
  const language = useSelector((state) => state.language.lang);
  const getBranches = useSelector((state) => state.branches.branches);
  // console.log(getBranches)
  const allbranches = useSelector((state) => state.branches);
  // console.log(allbranches)
  const [addresses, setaddresses] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)

  const data = JSON.parse(accessToken);
  const [loc, setloc] = useState({});
  function haversineDistance(lat1, lon1, lat2, lon2) {
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.watchPosition(
        // Success callback function
        function (position) {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const earthRadius = 6371; // Radius of the Earth in kilometers

          const dLat = (lat2 - lat) * (Math.PI / 180);
          const dLon = (lon2 - lng) * (Math.PI / 180);

          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
              Math.cos(lat2 * (Math.PI / 180)) *
              Math.sin(dLon / 2) *
              Math.sin(dLon / 2);

          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

          const distance = earthRadius * c; // Distance in kilometers
          // console.log("distance", distance);

          return distance;
          // Update the map with the user's new location
        },
        // Error callback function
        function (error) {
          // Handle errors, e.g. user denied location sharing permissions
          // console.error("Error getting user location:", error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  }
  const [mylocdata, setmylocdata] = useState({
    lat: '',
    lng: '',
  });

  const getbranches = () => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .get(`${BASE_URL}api/web/v1/site/branch`, options)
      .then((res) => {
        // console.log(res.data.data)
        let branchesarr = [...res.data.data];
        // console.log(branchesarr);
        for (let i = 0; i < branchesarr.length; i++) {
          // console.log(branchesarr[i]);
          if ("geolocation" in navigator) {
            navigator.geolocation.watchPosition(function (position) {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              // console.log("lartt", lat, lng);
              const d = haversineDistance(
                parseFloat(lat),
                parseFloat(lng),
                parseFloat(branchesarr[i].lat),
                parseFloat(branchesarr[i].lang)
              );
              branchesarr[i]['wsd'] = 'rere';
              // console.log(d);
            });
          }
        }
        setbranches(branchesarr);
        // console.log(branchesarr)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getbranches();
  }, [language]);

  const [branch_d, setbranch_d] = useState({
    lang: '',
    lat: '',
  });
  const [allbranch_d, setallbranch_d] = useState({});

  const dispatch = useDispatch();
  const updateBranchData = (allbranch_d) => {
    setbranchdata({
      lang: allbranch_d.lang,
      lat: allbranch_d.lat,
    });
    // toast.success(language == 'ar' ? "تم تغير الموقع بنجاح" : "Location Has Changed Successfully")
    dispatch(updateBranch(JSON.stringify(allbranch_d)));
    dispatch(deletecart());
    dispatch(updateCartData());
  };
  const [branchdata, setbranchdata] = useState({
    lat: '',
    lang: '',
  });
  const [branch_zo, setbranch_zo] = useState(280);
  const getbranchdata = () => {
    setbranchdata({
      late: allbranches.branch.lat,
      lang: allbranches.branch.lang,
    });
  };

  useEffect(() => {
    getbranchdata();
  }, []);

  const getaddresses = () => {
    // console.log(data?.access_token);
    axios
      .get(
        `${BASE_URL}api/web/v1/product/address?access-token=${data?.access_token}`
      )
      .then((res) => {
        let newarr = [];
        setmylocdata({
          lat: res?.data?.data[0]?.items[0].late,
          lng: res?.data?.data[0]?.items[0].lang,
        });
        // newarr=res?.data?.data[0]?.items.sort();
        setaddresses(res?.data?.data[0]?.items);
        // console.log(res?.data?.data[0]?.items)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getaddresses();
    setbranch_d({
      lat: getBranch.lat,
      lang: getBranch.lang,
    });
  }, []);
  useEffect(() => {}, []);
  const handleloc = (loc, d) => {};
  const setBranches = (id, distance) => {
    // console.log(branches);
    if (branches && branches.length && id && distance) {
      const newArrBranches = [];
      branches.map((item) => {
        if (item.id == id) {
          item.distanceD = distance;
        }
        if (!newArrBranches.includes(item)) {
          console.log(item);
          newArrBranches.push(item);
        }
      });
      console.log(
        newArrBranches,
        newArrBranches.sort(
          (a, b) => parseFloat(a.distanceD) - parseFloat(b.distanceD)
        )
      );
      setbranches(
        newArrBranches.sort(
          (a, b) => parseFloat(a.distanceD) - parseFloat(b.distanceD)
        )
      );
    }
  };
  // useEffect(() => {
  //   setBranches();
  // }, []);
  useEffect(() => {
    setbranch_zo(12);
    setbranch_zo(11);
  }, [branchdata]);
  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb title={language == "ar" ? "فروعنا" : "Our Branches"} />

          <BranchMap
            branch_zo={branch_zo}
            branch_d={branch_d}
            getBranch={getBranch}
            branches={branches}
          />
          {showconf ? (
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <span style={{ fontWeight: "bold", margin: "0 10px" }}>
                {language == 'ar'
                  ? "سوف يتم حذف العناصر من السلة"
                  : "Cart Items Will Be Removed"}
              </span>
              <button
                style={{
                  width: '240px',
                  maxWidth: 'fit-content',
                  color: 'white',
                  backgroundColor: `var(--main-color)`,
                  padding: '10px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  updateBranchData(allbranch_d);
                }}
              >
                {"   "}
                {language == 'ar'
                  ? "تأكيد إختيار الفرع"
                  : "Confirm Choose This Branch"}
              </button>
            </div>
          ) : null}
          <div className="branches">
            {branches.length > 0 && branches != null
              ? branches.map((item, index) => {
                  return (
                    <Brnach
                      handleloc={handleloc}
                      // handlemyloc={(loc)=>{
                      //     console.log(loc)
                      //   }}
                      branch_zo={branch_zo}
                      branch_zoom={() => {
                        setbranch_zo(36);
                      }}
                      setBranches={setBranches}
                      mylocdata={mylocdata}
                      branch={item}
                      updateBranch={(branch) => {
                        setshowconf(true);
                        setbranch_d({ lat: branch.lat, lang: branch.lang });
                        setallbranch_d({ ...branch });
                      }}
                    />
                  );
                })
              : null}
          </div>
        </>
      }
    />
  );
};

export default Branches;
