import React, { useEffect, useState } from 'react';
import Brudcrumb from '../../components/brudcrumb/brudcrumb';
import DefaultLayout from '../../layout/defaultLayout';
import { useDispatch, useSelector } from 'react-redux';
import './waytogetorder.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Loader, SelectPicker } from 'rsuite';
import TimePicker from 'react-time-picker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { SelectPicker } from 'rsuite';
import {
  AiFillCloseCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from 'react-icons/ai';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import moment from 'moment/moment';
import {
  adddelivery,
  updateCartData,
  updateCartDataWay,
  updateloc,
} from '../../store/cartReducer';
import { ToastContainer, toast } from 'react-toastify';
import Map from './Map';
import { BASE_URL } from '../../CONSTS';
// import EditMap from '../MyAddresses/EditMAp/EditMap';
const WayToGetOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  // console.log(data)
  const [time, settime] = useState(null);
  const [date, setdate] = useState("");
  const [showdate, setshowdate] = useState(false);
  const data = accessToken ? JSON.parse(accessToken) : null;
  const [alllocations, setalllocations] = useState("");
  const language = useSelector((state) => state.language.lang);
  const [activetitle, setactivetitle] = useState(0);
  const [selectedloc, setselectedloc] = useState("");
  const [lat, setlat] = useState("");
  const [price, setprice] = useState("");
  const [anothertime, setanothertime] = useState("");
  const [lang, setlang] = useState("");
  const [selectedcity, setselectedcity] = useState("");
  const [selectedstate, setselectedstate] = useState("");
  const [mylocation, setmylocation] = useState("");
  const [showmap, setshowmap] = useState(false);
  const getBranch = useSelector((state) => state.branches.branch);
  // console.log(getBranch);
  useEffect(() => {
    const currentTime = new Date();

    const desiredOpenTime = new Date(currentTime);
    desiredOpenTime.setHours(...getBranch?.open_time?.split(":"));

    const desiredCloseTime = new Date(currentTime);
    desiredCloseTime.setHours(...getBranch?.closed_time?.split(":"));

    const openTime = currentTime >= desiredOpenTime;
    const closedTime = currentTime <= desiredCloseTime;
    // console.log(getBranch, !closedTime)
    if (!closedTime && !openTime) {
      toast.error(
        language == "ar"
          ? "الوقت الذي أدخلته خارج دوام الفرع"
          : "The branch is not open at the moment"
      );
      return navigate("/");
    }
  }, [getBranch]);

  const getCart = useSelector((state) => state.cart.data);
  useEffect(() => {
    if (!getCart.length) {
      return navigate("/");
    }
  }, [getCart]);

  const getUser = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!getUser && !getUser.length) {
      return navigate("/");
    }
  }, [getUser]);

  const [addresses, setaddresses] = useState([]);
  const [addressesloading, setaddressesloading] = useState([false]);
  const [showtimepicker, setshowtimepicker] = useState(false);
  const [showalltime, setshowalltime] = useState(false);
  const [delivery, setdelivery] = useState("");

  const [myaddress, setmyaddress] = useState("");

  const [cities, setcities] = useState([]);

  const newhandlethings = async () => {
    var alladdresses = [];
    var allcitiesdata = [];

    let pushedarr = [];
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    await axios
      .get(
        `${BASE_URL}api/web/v1/product/address?access-token=${data?.access_token}`,
        options
      )
      .then((res) => {
        alladdresses = [...res?.data?.data[0]?.items];
        // console.log(alladdresses.filter((item) => item?.is_default == 1)[0]);
        setselectedloc(
          alladdresses.filter((item) => item?.is_default == 1)[0]?.id
        );
        setselectedstate(res?.data?.data[0]?.items[0].state);
        pushedarr = alladdresses.filter((item) => item?.is_default == 1);
      });

    await axios.get(`${BASE_URL}api/web/v1/list`, options).then((res) => {
      // console.log(res.data.data,"City")
      allcitiesdata = [...res?.data?.data[0]?.city];
      // console.log(
      //   "res?.data?.data[0]?.city[0]",
      //   res?.data?.data[0]?.city?.delivery
      // );
      setselectedcity(res?.data?.data[0]?.city[0].id);
      dispatch(
        updateCartDataWay({
          waydeliv: 0,
          address: myaddress,
          order_time: null,
          date: null,
          lat: lat,
          lang: lang,
          alllocations: alllocations,
          location: selectedloc,
          state: selectedstate,
          city_id: res?.data?.data[0]?.city[0].id,
          delivery: res?.data?.data[0]?.city[0].delivery,
          map_location: map_location,
        })
      );
    });
    for (let i = 0; i < alladdresses?.length; i++) {
      for (let j = 0; j < allcitiesdata?.length; j++) {
        if (alladdresses[i].city.id == allcitiesdata[j].id) {
          alladdresses[i]['delivery'] = allcitiesdata[j].delivery;
          // return 0;
        }
      }
      pushedarr.push(alladdresses[i]);
    }
    setselectedloc(pushedarr[0].id);
    setmyaddress(pushedarr[0].address);
    setlat(pushedarr[0].late);
    // console.log(pushedarr[0].map_location)
    setlang(pushedarr[0].lang);
    dispatch(adddelivery({ delivery: pushedarr[0].delivery }));
    handleadddeliveryprice(pushedarr[0].delivery);
    setaddresses(pushedarr);
    // console.log(pushedarr);
    setmylocation(
      `${pushedarr[0]?.late} , ${pushedarr[0]?.lang}, ${pushedarr[0]?.map_location}`
    );
    setselectedcity(pushedarr[0]?.city?.id);
    setselectedstate(pushedarr[0]?.state);
    dispatch(
      updateCartDataWay({
        waydeliv: 0,
        address: pushedarr[0].address,
        order_time: null,
        date: null,
        lat: pushedarr[0].late,
        lang: pushedarr[0].lang,
        alllocations: alllocations,
        location: pushedarr[0].address,
        state: selectedstate,
        city_id: selectedcity,
        delivery: pushedarr[0].delivery,
        map_location: pushedarr[0]?.map_location,
      })
    );
    setmap_location(pushedarr[0]?.map_location);
    setaddressesloading(false);
  };

  const handleadddeliveryprice = (delivery) => {
    // console.log(delivery)
  };

  const getCities = () => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios.get(`${BASE_URL}api/web/v1/list`, options).then((res) => {
      // console.log(res);
      setcities(res.data.data[0].city);
      // console.log(res.data.data[0].city,"trrrrrrrrrrrrr")
      setselectedcity(res.data.data[0].city[0].city_id);
      // for(let i=0;i<)
      /* hir */
      dispatch(
        updateCartDataWay({
          // waydeliv: 0,
          address: product.address,
          order_time: null,
          date: null,
          lat: lat,
          lang: lang,
          alllocations: alllocations,
          location: selectedloc,
          state: selectedstate,
          city_id: res.data.data[0].city[0].city_id,
          delivery: res.data.data[0].city[0]?.delivery,
          map_location: map_location,
        })
      );
    });
  };

  useEffect(() => {
    // console.log(addresses);
    setselectedloc(addresses.filter((item) => item?.is_default)[0]?.id);
    // console.log(addresses);
  }, [addresses]);

  useEffect(() => {
    getCities();
  }, []);
  const [value, onChange] = useState('10:00');

  const product = useSelector((s) => s.cart);
  // console.log(product);

  useEffect(
    () => {
      if (selectedcity) {
        let arr = [...cities];
        // console.log(arr)
        let newarr = arr.filter((item) => item.id == selectedcity);
        // console.log(newarr);
        setdelivery(newarr[0]?.delivery);
        // console.log("selectedcity", selectedcity);
        dispatch(
          updateCartDataWay({
            // waydeliv: activetitle == 1 ? 0 : 1,
            waydeliv: 0,
            address: myaddress !== '' ? myaddress : product.address,
            order_time: null,
            date: null,
            lat: lat,
            lang: lang,
            alllocations: alllocations,
            location: selectedloc,
            map_location: map_location,
            state: selectedstate,
            city: newarr[0]?.id,
            delivery: newarr[0]?.delivery,
            city_id: newarr[0]?.id,
          })
        );
        dispatch(updateCartData());
      }
    },
    [selectedcity],
    cities
  );

  const [psfromdata, setpsfromdata] = useState({
    lat: "",
    lng: "",
    map_location: '',
  });
  const [map_location, setmap_location] = useState('');
  useEffect(() => {
    // console.log(selectedcity);
    // console.log(selectedloc);
  }, [selectedloc]);

  const [minmalData, setMinimalData] = useState(false);
  const getminimal = () => {
    const options = {
      headers: {
        "Accept-Language": language,
      },
    };
    axios
      .get(`${BASE_URL}api/web/v1/product/order-limit`, options)
      .then((res) => {
        // console.log("MinimalData", res.data.data);
        setMinimalData(res.data.data);
      });
  };
  useEffect(() => {
    getminimal();
  }, []);
  // useEffect(()=>{
  //   dispatch(
  //     updateCartDataWay({
  //       waydeliv: null,
  //       order_time: null,

  //     })
  //   );
  //   dispatch(updateCartData());
  // },[])
  // useEffect(()=>{
  //   updateCartDataWay({
  //     // waydeliv: activetitle == 1 ? 0 : 1,
  //     waydeliv:0,
  //     address: product.address,
  //     order_time: null,
  //     date: null,
  //     lat: lat,
  //     lang: lang,
  //     alllocations: alllocations,
  //     location: selectedloc,
  //     map_location: map_location,
  //     state: selectedstate,
  //     city: product.city_id,
  //     delivery: product.delivery,
  //     city_id: product?.id
  //   })
  // },[])
  useEffect(() => {
    newhandlethings();
  }, []);
  return (
    <DefaultLayout
      children={
        <>
          <Brudcrumb
            title={`${
              language === 'ar'
                ? 'طريقة إستلام الطلب'
                : 'Way To Receiving The Order'
            }`}
          />
          <div className="waytogetorder" dir={language == "ar" ? "ltr" : "rtl"}>
            <>
              {!minmalData?.hide_delivery ? (
                <div
                  className="first_way"
                  onClick={() => {
                    newhandlethings();
                    setactivetitle(1);
                    dispatch(
                      updateCartDataWay({
                        waydeliv: 0,
                        address: myaddress,
                        order_time: null,
                        date: null,
                        lat: lat,
                        lang: lang,
                        alllocations: alllocations,
                        location: selectedloc,
                        state: selectedstate,
                        city_id: selectedcity,
                        delivery: delivery,
                        map_location: psfromdata?.map_location,
                      })
                    );
                  }}
                >
                  <div
                    className="firt_way_title"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      onClick={() => {
                        // setactivetitle(1)
                        // // getmyaddress();
                        // newhandlethings();
                      }}
                      className={
                        activetitle == 1 ? 'bigcheckone active' : 'bigcheckone'
                      }
                    ></div>
                    <div>
                      <h5>
                        {language == "ar"
                          ? "توصيل للعنوان"
                          : "Delivery to the address"}
                      </h5>
                      <img
                        src={require("../../assets/delivery.svg").default}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </>
            {activetitle == 1 ? (
              <div
                className="first_way_ways"
                dir={language == "en" ? "ltr" : "rtl"}
              >
                {addressesloading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="add_new_loc">
                      <h4>
                        {language == 'ar'
                          ? "اختيار عنوان محفوظ"
                          : "Select Preserved Location"}
                      </h4>
                      <button
                        onClick={() => {
                          setactivetitle(0);
                          navigate("/addloc");
                        }}
                      >
                        {language == 'ar' ? 'أضف عنوان' : 'Add Location'}
                      </button>
                    </div>
                    <select
                      value={selectedloc}
                      onChange={(e) => {
                        setselectedloc(e.target.value);

                        let selectedaddress = addresses.filter(
                          (it) => it.id == e.target.value
                        );
                        let item = selectedaddress[0];
                        // console.log(item);
                        setpsfromdata({
                          lat: item?.late,
                          lng: item?.lang,
                          map_location: item?.map_location,
                        });
                        setmylocation(
                          `${item?.late} , ${item?.lang}, ${item?.map_location}`
                        );

                        setselectedloc(item.id);
                        setmyaddress(item.address);
                        setlat(item.late);
                        setlang(item.lang);
                        setmap_location(item.map_location);
                        setdelivery(item.delivery);
                        setselectedcity(item.city.id);
                        setselectedstate(item.state);
                        dispatch(adddelivery({ delivery: item.delivery }));
                        handleadddeliveryprice(item.delivery);
                        // console.log("itemity", item);
                        dispatch(
                          updateCartDataWay({
                            waydeliv: 0,
                            address: item.address,
                            order_time: null,
                            date: null,
                            lat: item.late,
                            lang: item.lang,
                            alllocations: alllocations,
                            location: selectedloc,
                            state: selectedstate,
                            city_id: item.city.id,
                            delivery: item.delivery,
                            map_location: item.map_location,
                            maploc: item.map_location,
                          })
                        );
                      }}
                      className="cities_select"
                    >
                      {addresses.map((item, index) => {
                        return <option value={item.id}>{item.address}</option>;
                      })}
                    </select>
                  </>
                )}
                <div>
                  <div className="add_city">
                    <h4>
                      <span>{language == 'ar' ? 'المدينه' : 'City'}</span>
                      <span>*</span>
                    </h4>
                    <select
                      onChange={(e) => {
                        setselectedcity(e.target.value);
                        const city_id = e.target.value;
                        let filtered = cities.filter(
                          (item) => item.id == e.target.value
                        );
                        setdelivery(filtered[0]?.delivery);
                        const addData = {
                          waydeliv: 0,
                          address: myaddress,
                          order_time: null,
                          date: null,
                          lat: lat,
                          lang: lang,
                          alllocations: alllocations,
                          location: selectedloc,
                          state: selectedstate,
                          city_id: city_id,
                          delivery: filtered[0]?.delivery,
                          map_location: psfromdata?.map_location,
                        };

                        dispatch(updateCartDataWay(addData));
                        dispatch(updateCartData());
                        // console.log("del", filtered[0]?.delivery);
                      }}
                      value={selectedcity}
                      className="cities_select"
                    >
                      {cities.map((item, index) => {
                        // console.log(item)
                        return (
                          <option value={item.id}>
                            {`${language == 'ar' ? item.name : item.name_en} ${
                              language == 'ar'
                                ? `سعر التوصيل ${item.delivery} `
                                : ` Delivry Price ${item.delivery}`
                            }`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="enter_region">
                    <h4>
                      <span>{language == 'ar' ? 'الحى' : 'neighborhood'}</span>
                      <span>*</span>
                    </h4>
                    <input
                      value={selectedstate}
                      type="text"
                      placeholder={language == 'ar' ? 'الحى' : 'neighborhood'}
                    />
                  </div>
                  <div className="enter_location">
                    <div>
                      <h4>
                        <span>
                          {language == 'ar'
                            ? 'تحديد موقعك'
                            : 'Select Your Location'}
                        </span>
                        <span>*</span>
                      </h4>
                      <button
                        onClick={() => {
                          setshowmap(true);
                        }}
                      >
                        {language == 'ar'
                          ? 'حدد موقعك'
                          : 'Select Your Location'}
                      </button>
                    </div>
                    <input value={mylocation} type="text" />
                  </div>
                  <div className="my_addresses">
                    <h4>
                      <span>
                        {language == 'ar' ? 'عناوينى' : 'My Addresses'}
                      </span>
                      <span>*</span>
                    </h4>
                    <input
                      defaultValue={selectedstate}
                      value={alllocations}
                      onChange={(e) => {
                        setalllocations(e.target.value);
                        dispatch(
                          updateCartDataWay({
                            waydeliv: 0,
                            address: myaddress,
                            order_time: null,
                            date: null,
                            lat: lat,
                            lang: lang,
                            alllocations: e.target.value,
                            location: selectedloc,
                            state: selectedstate,
                            city_id: selectedcity,
                            delivery: delivery,
                            map_location: psfromdata?.map_location,
                          })
                        );
                      }}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {!minmalData?.hide_branch_tasleem ? (
              <div
                onClick={() => {
                  setactivetitle(2);
                  dispatch(
                    updateCartDataWay({
                      waydeliv: 1,
                      address: null,
                      order_time: null,
                      date: null,
                      lat: null,
                      lang: null,
                      alllocations: activetitle == 1 ? alllocations : null,
                      location: null,
                      state: null,
                      city: null,
                      delivery: null,
                    })
                  );
                }}
                className="second_way"
              >
                <div
                  className="second_way_title"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    onClick={() => {
                      setactivetitle(2);
                      dispatch(
                        updateCartDataWay({
                          waydeliv: 1,
                          address: null,
                          order_time: null,
                          date: moment(new Date()).format("YYYY-MM-DD"),
                          lat: null,
                          lang: activetitle == 1 ? lang : null,
                          alllocations: activetitle == 1 ? alllocations : null,
                          location: activetitle == 1 ? selectedloc : null,
                          state: activetitle == 1 ? selectedstate : null,
                          city_id: activetitle == 1 ? selectedcity : null,
                          delivery: activetitle == 1 ? delivery : null,
                        })
                      );
                      dispatch(updateCartData());
                    }}
                    className={
                      activetitle == 2 ? "bigchecktwo active" : "bigchecktwo"
                    }
                  ></div>
                  <div>
                    <h5
                      style={{ margin: "0 10px", color: "var(--main-color)" }}
                    >
                      <span>
                        {language === "ar"
                          ? getBranch?.name
                          : getBranch?.name_en}
                      </span>
                      <img
                        src={require("../../assets/location.svg").default}
                        alt=""
                        style={{ margin: "4ox" }}
                      />
                    </h5>
                    <h5>
                      {language == "ar"
                        ? "إستلام من الفرع"
                        : "received from branch"}
                    </h5>
                    <img
                      src={require("../../assets/shop.svg").default}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {showalltime &&
            product?.order_time &&
            product?.order_time?.length ? (
              <div
                className="alltimediv"
                dir={language == "ar" ? "ltr" : "rtl"}
              >
                <div>
                  <img
                    src={require("../../assets/icon_sensor-alert(1).png")}
                    alt=""
                  />
                  <h4>
                    {language == 'ar'
                      ? "وقت إستلام الطلب"
                      : "Time To Get Order"}
                  </h4>
                </div>
                <div>
                  {/* {console.log(product)} */}
                  <h4>{product.order_time}</h4>
                  {/* <h4>{date}</h4> */}
                </div>
              </div>
            ) : null}
            {activetitle == 2 ? (
              <div className="second_way_ways">
                <img
                  src={require("../../assets/icon_calendar-clock.png")}
                  alt=""
                />
                <label
                  onClick={() => {
                    setshowtimepicker(true);
                  }}
                  htmlFor="datepicker"
                  style={{ justifyContent: "center" }}
                >
                  <h5>
                    {language == 'ar'
                      ? "تحديد وقت الإستلام"
                      : "Determine The Time Of Receipt"}
                  </h5>
                  <img src={require("../../assets/hour.svg").default} alt="" />
                </label>
              </div>
            ) : null}
            {showtimepicker ? (
              <div className={showtimepicker ? "conftime active" : "conftime"}>
                <AiOutlineClose
                  onClick={() => {
                    setshowtimepicker(false);
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticTimePicker
                    onChange={(e) => {
                      // console.log(e.$H);
                      // console.log(e.$m);
                      settime(e?.$H + ":" + e?.$m);
                      // console.log(e.$H+ ":" + e.$m)
                      let pusheddate = `${e?.$H + ":" + e?.$m}`;
                      // console.log(pusheddate)
                      dispatch(
                        updateCartDataWay({
                          waydeliv: 1,
                          address: null,
                          order_time: pusheddate,
                          date: moment(new Date()).format("YYYY-MM-DD"),
                          lat: null,
                          lang: null,
                          alllocations: null,
                          location: null,
                          state: null,
                          city_id: null,
                          delivery: null,
                          map_location: null,
                        })
                      );

                      // console.log(moment(e.$H).format("HH") + ":" + moment(e.$m).format("MM"))
                      // console.log(e.$m)
                      // console.log(e.$H)
                    }}
                    orientation="landscape"
                  />
                </LocalizationProvider>
                <button
                  onClick={(e) => {
                    setshowtimepicker(false);
                    setshowalltime(true);
                    // settime(e.$H+ ":" + e.$m);
                    // let pusheddate=`${e.$H+ ":" + e.$m}`;

                    //       dispatch(updateCartDataWay({ waydeliv: activetitle == 1 ? 2 : 1, address: activetitle == 1 ? myaddress : null, order_time: activetitle == 1 ? null : pusheddate, date: activetitle == 1 ? null : moment(new Date()).format("YYYY-MM-DD"), lat: activetitle == 1 ? lat : null, lang: activetitle == 1 ? lang : null,
                    //   alllocations: activetitle == 1 ? alllocations : null ,
                    // location:activetitle == 1?selectedloc:null,state:activetitle == 1?selectedstate:null,city:activetitle == 1?selectedcity:null,delivery:activetitle == 1?delivery:null}))
                  }}
                  className="confbtntime"
                >
                  {language == 'ar' ? 'تأكيد' : 'confirm'}
                </button>
              </div>
            ) : null}

            <button
              onClick={() => {
                if (product.waydeliv == 1 && product?.order_time == null) {
                  toast.warn("إختر الوقت أولا");
                  return;
                }

                if (product.waydeliv == 1) {
                  const currentTime = new Date();

                  const desiredOpenTime = new Date(currentTime);
                  // console.log(currentTime)
                  desiredOpenTime.setHours(...getBranch?.open_time?.split(":"));

                  const desiredCloseTime = new Date(currentTime);
                  desiredCloseTime.setHours(
                    ...getBranch?.closed_time?.split(":")
                  );

                  const thisOpenTime = new Date(currentTime);
                  thisOpenTime.setHours(...product?.order_time?.split(":"));

                  const openTime = thisOpenTime >= desiredOpenTime;
                  const closedTime = thisOpenTime <= desiredCloseTime;

                  if ((!openTime || !closedTime) && product?.waydeliv == 1) {
                    dispatch(
                      updateCartDataWay({
                        waydeliv: null,

                        order_time: null,
                      })
                    );
                    dispatch(updateCartData());
                    toast.error(
                      language == "ar"
                        ? "الوقت الذي أدخلته خارج دوام الفرع"
                        : "The branch is not open at the moment"
                    );
                    return;
                  }
                }

                if (activetitle !== 1 && activetitle !== 2) {
                  toast.warn(
                    language == 'ar'
                      ? "من فضلك أختر طريقه التوصيل"
                      : "Please select a Way To delivery"
                  );
                  return;
                }
                if (activetitle == 1) {
                  if (myaddress == "" || myaddress == null) {
                    toast.warn(
                      language == 'ar'
                        ? "من فضلك أختر العنوان"
                        : "Please Select The Address"
                    );
                    return;
                  }
                }
                if (activetitle == 2 && (time == "" || time == null)) {
                  toast.warn(
                    language == 'ar'
                      ? "من فضلك إختر الوقت"
                      : "Please select a time"
                  );
                  return;
                }
                dispatch(
                  updateCartDataWay({
                    waydeliv: product?.waydeliv,
                    address: product.address,
                    order_time: product.order_time,
                    date:
                      activetitle == 1
                        ? null
                        : moment(new Date()).format("YYYY-MM-DD"),
                    lat: product.lat,
                    lang: product.lang,
                    alllocations: product.alllocations,
                    location: product.location,
                    state: product.state,
                    city_id: product.city_id,
                    delivery: product.delivery,
                  })
                );
                if (product.waydeliv == 0) {
                  if (product.address == '' || product.address == null) {
                    toast.warn('من فضلك أدخل عنوان');
                    return;
                  }
                }
                navigate("/talabaty");
                dispatch(updateCartData());
              }}
              className="confirm_btn_order"
            >
              {language == 'ar' ? "تأكيد" : "Confirm"}
            </button>
          </div>

          {showmap ? (
            <div className="flaotmap">
              <AiOutlineClose
                onClick={() => {
                  setshowmap(false);
                }}
              />
              <Map
                onPress={(psData) => {
                  // console.log(psData)
                  setpsfromdata(psData);
                  // console.log(psData)

                  setmylocation(
                    `${psData?.lat} , ${psData?.lng}, ${psData?.map_location}`
                  );
                  dispatch(
                    updateloc({
                      late: psData.lat,
                      lang: psData.lang,
                      maploc: psData?.map_location || '',
                    })
                  );
                  dispatch(
                    updateCartDataWay({
                      waydeliv: 0,
                      address: myaddress,
                      order_time: null,
                      date: null,
                      lat: psData.lat,
                      lang: psData.lng,
                      alllocations: alllocations,
                      location: selectedloc,
                      state: selectedstate,
                      city_id: selectedcity,
                      delivery: delivery,
                      map_location: psData?.map_location,
                    })
                  );
                }}
              />
              <button
                onClick={() => {
                  setshowmap(false);
                }}
              >
                تأكيد
              </button>
            </div>
          ) : null}

          <ToastContainer />
          {/* <TimePicker onChange={onChange} value={value} /> */}
        </>
      }
    />
  );
};

export default WayToGetOrder;
